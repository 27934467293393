import CrioTextField from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioTextField';
import crioTheme from '@crio/crio-react-component/dist/cjs/config/crioTheme';
import { faWarning } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, ThemeProvider, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AnswerType, isAnySandboxMode } from '../../../enums';
import { RecordQuestion } from '../../../types';

const Container = styled('div')`
  .WarningIcon {
    margin-right: 5px;
    margin-bottom: 3px;
    vertical-align: middle;
    color: #FF9933;
    height: 18px;
  }

  ul.Answers {
    list-style-type: disc; 
    margin-top: 0;
    margin-bottom: 0;
    color: ${(props) => props.theme.palette.grey[600]};
  }
`;

/* WARNING
  We definitely do not want to get anything from the context directly at the question-level.
  Context value, when updated, triggers a blind rerender of EVERYTHING that makes use of it
  via useContext. Instead, things like handleAnswerChange and the dataPoints nested inside
  records are provided as props, therefore dodging the expensive context rerenders.
*/
function ReferenceQuestion(props: RecordQuestion) {
  const { t } = useTranslation();
  const {
    variableName, recordId, handleAnswerChange, dataPoint, questionId, referencedDataPoint, criteria, visitMode,
  } = props;

  let { answer, answer_type } = dataPoint || {};
  let { answer: referencedAnswer, answer_type: referencedAnswerType } = referencedDataPoint || {};

  if (referencedAnswer instanceof Array) {
    referencedAnswer = (referencedAnswer as Array<string>).join('\n');
    referencedAnswerType = AnswerType.BULLET;
  }

  const isMultiSelect = referencedAnswerType === AnswerType.BULLET;

  const isAnswerSet = answer !== undefined && answer !== null;
  if (!isAnswerSet) {
    answer = referencedAnswer;
    answer_type = referencedAnswerType;
  }

  useEffect(() => {
    if (!isAnswerSet) {
      const newDataPoint = {
        ...dataPoint, answer, answer_type, referenced_answer_type: answer_type,
      };
      handleAnswerChange({
        variableName,
        questionId,
        newDataPoint,
        recordId,
        runRules: false, // do not want to run alert rules for reference questions, disable logic handled elsewhere
      });
    }
  }, [isAnswerSet]);

  const hasAnswer = answer !== undefined
  const hasError = !isAnySandboxMode(visitMode) && (!criteria?.visitFound || !criteria?.procedureFound || !criteria?.questionFound);

  console.debug('ReferenceQuestion', {
    props, isMultiSelect, hasAnswer, hasError, answer, answer_type,
  });

  return (
    <Container>
      <ThemeProvider theme={crioTheme}>
        {hasError && (
        <span>
          <FontAwesomeIcon className="WarningIcon" icon={faWarning} />
          <span className="ReferencedValue">
            {t('Procedure.Question.Reference.Referenced Value Configuration Error')}
          </span>
        </span>
        )}
        {(!hasError && !hasAnswer) && <span className="ReferencedValue">{t('Procedure.Question.Reference.Referenced Value')}</span>}
        {(!hasError && hasAnswer && !isMultiSelect) && (
        <CrioTextField
          type="text"
          name={variableName}
          value={answer}
          disabled
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newDataPoint = {
              ...dataPoint,
              answer: e.target.value,
            };
            handleAnswerChange({
              variableName,
              questionId,
              newDataPoint,
              recordId,
              runRules: newDataPoint?.answer !== dataPoint?.answer,
            });
          }}
        />
        )}
        {(!hasError && hasAnswer && isMultiSelect) && (
        <Typography>
          <ul className="Answers">
            {(answer as string).split('\n').map((item) => (<li key={item}>{item}</li>))}
          </ul>
        </Typography>
        )}
      </ThemeProvider>
    </Container>
  );
}
export default ReferenceQuestion;
