import CrioAlertDialog from '@crio/crio-react-component/dist/cjs/components/Feedback/CrioAlertDialog';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface UnsavedChangesDialogProps {
  isOpen: boolean,
  continueHandler?: Function,
  closeHandler?: Function,
}

const StyledCrioDialog = styled(CrioAlertDialog)`
  max-width: 520px;
  
  .optionsContainer {
    display: flex;
    margin-top: 10px;
    align-items: center;
  }

  .continueButton {
    cursor: pointer;
    padding-left: 10px;
    color: ${(props) => props.theme.palette.grey[600]};
    &:hover {
      color: ${(props) => props.theme.palette.grey[700]};
    }
  }
`;

export default function UnsavedChangesDialog({
  continueHandler = () => {},
  closeHandler = () => {},
  isOpen,
}: UnsavedChangesDialogProps) {
  const { t } = useTranslation();
  return (
    <StyledCrioDialog
      alertTitle={t('Procedure.Dialog.Unsaved Changes.Header')}
      type="Info"
      open={isOpen}
      onClose={() => closeHandler()}
      fullWidth={false}
      disablePortal
      sx={{ width: '50%', margin: 'auto' }}
    >
      <span>{t('Procedure.Dialog.Unsaved Changes.Message')}</span>
      <div className="optionsContainer">
        <CrioButton colorTheme="Secondary" onClick={() => closeHandler()}>{t('Common.Cancel')}</CrioButton>
        <div
          role="button"
          tabIndex={0}
          data-qa-id="continue"
          className="continueButton"
          onClick={() => continueHandler()}
          onKeyDown={() => continueHandler()}
        >
          {t('Procedure.Dialog.Unsaved Changes.Leave Without Saving')}
        </div>
      </div>

    </StyledCrioDialog>
  );
}

UnsavedChangesDialog.defaultProps = {
  continueHandler: () => {},
  closeHandler: () => {},
};
