import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import RadioGroup from '@crio/crio-react-component/dist/cjs/components/Inputs/RadioGroup';
import { RadioOption } from '@crio/crio-react-component/dist/cjs/components/Inputs/RadioGroup/RadioGroup';
import { styled } from '@mui/material';
import { RecordQuestion } from '../../../types';
import { getAnswerCommentsJson, getAnswerOptionsDataList, parseAnswerCommentJson } from '../../../util/answerComments';
import { AnswerType, isSyntheticQuestionType, VisitModeType } from '../../../enums';
import DataListQuestion from './DataListQuestion';

const StyledRadioGroup = styled(RadioGroup)`
  label:first-of-type {
  }
`;

/* WARNING
  We definitely do not want to get anything from the context directly at the question-level.
  Context value, when updated, triggers a blind rerender of EVERYTHING that makes use of it
  via useContext. Instead, things like handleAnswerChange and the dataPoints nested inside
  records are provided as props, therefore dodging the expensive context rerenders.
*/
function SingleSelectQuestion(props: RecordQuestion) {
  const {
    variableName, dataPoint, handleAnswerChange, answerOptions, recordId, questionId, readOnly, visitMode,
  } = props;
  const { t } = useTranslation();
  const { studyId } = useParams();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { answer, answer_comment } = dataPoint || {};
  const isSyntheticQuestion = isSyntheticQuestionType(questionId);
  const commentsUnchanged = (newAnswer: string | undefined): boolean => newAnswer !== answer;
  if (dataPoint) {
    dataPoint.answer_type = AnswerType.TEXT;
  }

  // Convert all of the Answer Options to Radio Options
  const radioOptions = (answerOptions?.map(({ text, commentRequired, answerCode }) => ({
    checked: answer === text,
    comment: parseAnswerCommentJson(answer_comment || '{}', text),
    name: text,
    value: text,
    code: answerCode,
    commentRequired: !!commentRequired,
  })) || []);

  // Get the Data List External ID for the currently selected answer, if any
  const answerOptionsDataList = getAnswerOptionsDataList(answerOptions, answer);

  /**
   * Handle the updating of an Answer Option
   * This will also update the Data Point and potentially trigger Procedure Logic
   * Thus this should be called any time the new Answer/Answer Comment is "finalized"
   * @param _event    ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null containing the update event
   * @param options   Array<RadioOption> containing all Radio Options
   */
  const handleRadioOptionChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null, options: Array<RadioOption>) => {
    const { value, comment, code } = options.find((o) => o.checked) || {};
    const newComment = getAnswerCommentsJson(comment, value);
    const newDataPoint = {
      ...dataPoint,
      answer_comment: newComment,
      answer: value || '',
      answer_code: code || '',
    };

    const answerChangeObject: any = {
      variableName,
      newDataPoint,
      recordId,
      questionId,
      runRules: commentsUnchanged(value),
    };

    if (event) {
      // There was a change event on the radio button itself
      answerChangeObject.selectionValueChange = value || '';
    }

    handleAnswerChange(answerChangeObject);
  };

  /**
   * Handle the updating of an Answer Comment
   * @param answerCommentValue   string containing the new Comment value
   */
  const handleAnswerCommentChange = (answerCommentValue: string) => {
    const newRadioOptions = radioOptions.map((option) => (
      {
        ...option,
        comment: (option.value === answer) ? answerCommentValue : option.comment,
      }
    ));

    handleRadioOptionChange(null, newRadioOptions);
  };

  return (
    <StyledRadioGroup
      disabled={readOnly}
      name={variableName || ''}
      value={answer || ''}
      row={!!isSyntheticQuestion}
      radioOptions={radioOptions}
      onRadioOptionsChange={handleRadioOptionChange}
      showCommentIcon={visitMode === VisitModeType.PROCEDURE_PREVIEW}
      className="RadioGroup"
      renderComment={(
        <DataListQuestion
          multiline
          variableName={variableName!}
          studyId={studyId!}
          dataListExternalId={answerOptionsDataList?.externalId}
          restrictedToDataList={answerOptionsDataList?.isRestrictedToDataList}
          readOnly={readOnly}
          value={parseAnswerCommentJson(answer_comment, answer) || ''}
          placeholder={t('Common.Enter Comment')}
          onBlur={handleAnswerCommentChange}
        />
      )}
    />
  );
}

export default SingleSelectQuestion;
