import CrioAlertDialog from '@crio/crio-react-component/dist/cjs/components/Feedback/CrioAlertDialog';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface DeleteProgressNoteDialogProps {
  isOpen: boolean,
  continueHandler?: Function,
  closeHandler?: Function,
}

const StyledCrioDialog = styled(CrioAlertDialog)`
  max-width: 520px;
  .message {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .optionsContainer {
    display: flex;
    margin-top: 10px;
    align-items: center;
  }
  .cancelButton {
    cursor: pointer;
    padding-left: 20px;
    color: ${(props) => props.theme.palette.grey[600]};
    &:hover {
      color: ${(props) => props.theme.palette.grey[700]};
    }
  }
`;

export default function DeleteProgressNoteDialog({
  isOpen,
  continueHandler = () => {},
  closeHandler = () => {},
}: DeleteProgressNoteDialogProps) {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <StyledCrioDialog
      data-testid="deleteProgressNoteDialog"
      alertTitle={t('Procedure.Dialog.Delete Progress Note.Header')}
      type="Error"
      open={isOpen}
      onClose={() => closeHandler()}
      fullWidth={false}
      disablePortal
      sx={{ width: '50%', margin: 'auto' }}
    >
      <div className="message">
        {t('Procedure.Dialog.Delete Progress Note.Message')}
      </div>
      <div className="optionsContainer">
        <CrioButton
          colorTheme="Alert"
          onClick={() => {
            continueHandler();
            closeHandler();
          }}
        >
          {t('Common.Delete')}
        </CrioButton>
        <div
          role="button"
          tabIndex={0}
          data-qa-id="cancel"
          className="cancelButton"
          onClick={() => {
            closeHandler();
          }}
          onKeyDown={() => {
            closeHandler();
          }}
        >
          {t('Common.Cancel')}
        </div>
      </div>

    </StyledCrioDialog>
  );
}

DeleteProgressNoteDialog.defaultProps = {
  continueHandler: () => {},
  closeHandler: () => {},
};
