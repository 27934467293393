import {
  Fragment, memo, useCallback, useContext, useEffect, useState,
} from 'react';
import objectHash from 'object-hash';
import { useTranslation } from 'react-i18next';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import {
  faCaretDown, faCaretUp, faLock, faLockOpen,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { CrioTooltip } from '@crio/crio-react-component/dist/cjs/components/DataDisplay';
import { CrioLoadingButton } from '@crio/crio-react-component/dist/cjs/components/Inputs';
import { ProcedureQuestion } from './ProcedureQuestion';
import generateUids from '../../util/UidUtil';
import ProcedureContext, { AnswerChangeOptions } from '../../context/ProcedureContext';
import { Answer, DataPoint, QuestionInterface } from '../../types';
import {
  CarryForwardType,
  NormalQuestionType,
  SyntheticQuestionType,
  VisitModeType,
  isSyntheticQuestionType,
  isAnyProcedureMode,
} from '../../enums';
import { ProcedureRecord } from '../../context/types';
import Row from '../Row';
import { getDataPointsFromProcedureRecords } from '../../context/util';
import { formatDateTimeWithTranslation } from '../../util/dateTimeUtil';
import RequiresTranscriptionToggle from './RequiresTranscriptionToggle';

const MultiRecordTable = styled(Table)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]} !important;

  &.Closed {
    display: none;
  }
`;

const FolderTab = styled(Box)`
    height: 52px;
    position: relative;
    top: 10px;
    left: 28px;
    display: inline-block;
    background-color: ${(props) => props.theme.palette.grey[100]};

    &::before,
    &::after {
        height: 42px;
        width: 30px;
        border-radius: 10px 10px 0 0;
        background-color: ${(props) => props.theme.palette.grey[100]};
        content: "";
        position: absolute;
        top: 0;
        z-index: -1;
    }
    &::before {
        left: -20px;
        transform: skewX(-20deg);
    }
    &::after {
        right: -20px;
        transform: skewX(20deg);
    }
    span {
        display: flex;
        justify-content: space-around;
        margin-top: 8px;
        line-height: 24px;
        font-weight: 400;
        color: ${(props) => props.theme.palette.h.main};
        letter-spacing: 1px;
    }
    
    .iconContainer {
        width: 27px;
    }

    .lockOpen {
        margin: 4px 5px 0 2px;
        animation-iteration-count:1;
    }
    
    .lockClosed {
        margin: 4px 8px 0 1px;
        animation: bounce 0.5s;
    }
`;

const FolderPage = styled(Box)`
    background-color: ${(props) => props.theme.palette.grey[100]};
    padding: 0 30px 35px 30px;

    div.HeaderQuestions {
        display: flex;
    }

    div.TopRight {
        margin-bottom: auto;
        margin-left: auto;
        margin-top: 25px;
    }
`;

const Folder = styled(Box)`
    filter: drop-shadow(0 0 6px #00000040);
    padding: 12px 8px 0 8px;

    .LinkButton {
        padding: 20px 0 0;
        color: ${(props) => props.theme.palette.grey[700]};

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .uneditable div.QuestionText {
        color: ${(props) => props.theme.palette.grey[700]} !important;
    }
`;

const MultiRecordHeaderColumn = styled(TableCell)`
  font-family: ${(props) => props.theme.typography.fontFamily} !important;
  background-color: ${(props) => props.theme.palette.grey[200]} !important;
  color: ${(props) => props.theme.palette.grey[900]};
  font-size: inherit;
  font-weight: 500;
  padding: 20px 25px !important;
`;

const NoRecordsRow = styled.td`
    height: 56px;
    color: ${(props) => props.theme.palette.grey[600]};
    text-align: center;
    font-size: 14px;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.palette.grey[300]};
    background-color: ${(props) => props.theme.palette.grey[100]};
`;

const MultiRecordRow = styled(TableRow)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-bottom: none;
  border-top: none;
  background-color: ${(props) => props.theme.palette.grey.A100};

  .Instruction {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }

  &:nth-child(odd) {
    .MuiTableCell-root {
      cursor: pointer;
    }
  }

  &.Closed {
    display: none;
  }

  &.Expanded-record {
    .MuiTableCell-root {
      color: ${(props) => props.theme.palette.grey[900]};
    }
  }

  &.Opened {
    .MuiTableCell-root {
      border-bottom: none;
    }
  }

  .MuiTableCell-root {
    font-size: inherit;
    font-family: "Poppins Light", "Poppins", sans-serif;
    padding: 12px 25px;
    font-weight: 300;
    color: ${(props) => props.theme.palette.grey[900]};

    &.ExpandedRecordNumber {
      font-weight: 500;
      padding-right: 0;
      padding-bottom: 0;

      .ExpandedRecordHeader {
        padding-bottom: 12px;
        border-bottom: 1px dotted ${(props) => props.theme.palette.grey[500]};
      }
    }

    &.ExpandedRecordCaret {
      padding-left: 0;
      padding-bottom: 0;
      color: ${(props) => props.theme.palette.grey[700]};

      .ExpandedRecordHeader {
        padding-bottom: 12px;
        border-bottom: 1px dotted ${(props) => props.theme.palette.grey[500]};
        padding-left: 25px;
      }
    }

    .Question:first-of-type {
      padding-top: 15px;
    }
  }

  .caret-icon {
    text-align: right;
  }

  .MultiRecordButtonsRow {
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 18px 0;

    .ClearAndDeleteBtns {
      .LinkButton {
        color: ${(props) => props.theme.palette.grey[600]};
        font-weight: 400;
        font-family: "Poppins-Regular", "Poppins", sans-serif;
        cursor: pointer;
        padding: 7px 0 7px 20px;
        text-align: center;

        &:hover {
          color: ${(props) => props.theme.palette.error.main};
          text-decoration: underline;
        }

        &.clearButton:hover {
          color: ${(props) => props.theme.palette.linkText.main};
        }

        &:first-of-type {
          border-right: 1px dotted ${(props) => props.theme.palette.grey[500]};
          padding-right: 20px;
        }
      }
    }
  }
`;

const MultiRecordProcedureButtons = styled(Row)`
  padding-top: 30px;
  align-items: center;

  .LinkButton {
    color: ${(props) => props.theme.palette.linkText.main};
    cursor: pointer;
    padding: 7px 0 7px 20px;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const LastSaveText = styled(Row)`
  padding: 12px 0px;
  text-align: center;
  font-size: 0.8em;
  font-style: italic;
  color: ${(props) => props.theme.palette.grey[600]};
`;

interface StyledTableContainerProps {
  isPerm: boolean,
  isOnLogsPage: boolean,
}

const StyledDiv = styled.div<StyledTableContainerProps>`
  .RecordsAlreadyExistWrap {
    display: inline-block;
  }

  .RecordsAlreadyExistWarning + div[role=tooltip] {
    top: -30px !important;
  }

  .TableHeader {
    th {
      background-color: ${(props) => props.theme.palette.grey[300]} !important;
      color: ${(props) => props.theme.palette.grey[900]};
    }
  }
`;

const StyledRequiresTranscriptionToggle = styled('div')`
    display: flex;
    justify-content: flex-end;
`;

/**
 * Get the base number of columns to span based on the given questions
 * @param questions  Array<QuestionInterface> to base the number of columns on
 * @return           number of columns that each should span
 */
const getBaseColspan = (questions: Array<QuestionInterface>): number => questions
  .filter((question: QuestionInterface) => !isSyntheticQuestionType(question.type))
  .slice(0, 4).length + 1;

interface RecordProps {
  readOnly: boolean,
  recordUid: string,
  questions: Array<QuestionInterface>,
  record: ProcedureRecord,
  recordNum: number,
  isLoading: boolean,
  expanded: boolean,
  onSaveCallback: Function,
}

interface RecordPreviewProps {
  recordUid: string,
  questions: Array<QuestionInterface>,
  record: ProcedureRecord,
  recordNum: number,
  expanded: boolean,
  setExpanded: Function,
  // We memoize the preview which means if the props don't change, then the preview
  // doesn't change. Memo is not good at looking into nested objects which is what
  // the record is, so pass the hash in to more accurately determine when to rerender
  recordHash: string,
}

interface ExpandedRecordsInterface {
  [recordId: string]: boolean,
}

const RecordPreview = memo(({
  recordUid, expanded, recordNum, questions, setExpanded, record, recordHash,
}: RecordPreviewProps) => {
  const { t } = useTranslation();
  return (
    <MultiRecordRow data-hash={recordHash} data-testid={`multirecordRow-${recordNum}`} key={`collapsed:${recordUid}`} className={`RecordHeader${expanded ? ' Opened' : ''}`} onClick={() => setExpanded(!expanded, recordUid)}>
      {expanded
        ? (
          <>
            <TableCell colSpan={getBaseColspan(questions)} className="ExpandedRecordNumber">
              <div className="ExpandedRecordHeader">
                {t('Procedure.MultiRecord.Record')}
                {' '}
                {recordNum}
              </div>
            </TableCell>
            <TableCell colSpan={1} className="ExpandedRecordCaret caret-icon">
              <div className="ExpandedRecordHeader"><FontAwesomeIcon icon={faCaretUp as IconProp} /></div>
            </TableCell>
          </>
        )
        : (
          <>
            <TableCell>
              {recordNum}
            </TableCell>
            {
              questions.filter(({ type }) => !isSyntheticQuestionType(type)).slice(0, 4).map(
                ({ questionId }: QuestionInterface) => {
                  const { answer, is_disabled } = record[questionId];
                  return <TableCell key={`answerPreview:${recordUid}:${questionId}`}>{!is_disabled ? answer : ''}</TableCell>;
                },
              )
            }
            <TableCell className="caret-icon">
              <FontAwesomeIcon icon={faCaretDown as IconProp} />
            </TableCell>
          </>
        )}
    </MultiRecordRow>
  );
});

/**
 * Record component that handles rendering a Record component
 * @param questions
 * @param recordNum
 * @param recordUid
 * @param handleDelete
 * @constructor
 */
export function Record({
  questions,
  readOnly,
  recordUid,
  record,
  recordNum,
  isLoading,
  expanded,
  onSaveCallback,
}: RecordProps) {
  const { t } = useTranslation();
  const {
    handleAnswerChange: handleAnswerChangeInContext, clearRecord, deleteRecord, saveProcedure, visitMode,
  } = useContext(ProcedureContext);

  if (isLoading) {
    return null;
  }

  return (
    <MultiRecordRow
      data-testid={`multirecordContent-${recordNum}`}
      className={expanded ? 'Expanded-record' : 'Closed'}
      key={recordUid}
    >
      <TableCell colSpan={getBaseColspan(questions) + 1}>
        {questions.map((question: QuestionInterface) => {
          /*
          Anonymous functions will always get a new reference on each rerender, therefore triggering a rerender
          of any children regardless of if they're memoized or not. Memoize the function itself with useCallback
          as we don't want EVERY ProcedureQuestion to rerender on every change. The dependency array is dataPoint.
          This because we actually DO need a new reference when the specific datapoint changes or else the state
          inside the handleAnswerChangeInContext closure will contain stale state.
          */
          const dataPoint = record[question.questionId];
          const handleAnswerChange = useCallback((args: any) => {
            handleAnswerChangeInContext(args);
          }, [dataPoint]);

          // Don't bother rendering everything if it isn't even visible
          if (!expanded) {
            return null;
          }

          return (
            <ProcedureQuestion
              key={`question:${recordUid}:${question.questionId}`}
              showVariableName={visitMode === VisitModeType.PROCEDURE_PREVIEW}
              visitMode={visitMode}
              isLoading={false}
              dataPoint={dataPoint}
              handleAnswerChange={handleAnswerChange}
              {...question}
              recordId={recordUid}
              readOnly={readOnly}
            />
          );
        })}
        <Row className="MultiRecordButtonsRow">
          <CrioButton
            disabled={readOnly}
            colorTheme="Primary"
            onClick={() => saveProcedure({ successCallback: () => onSaveCallback() })}
          >
            {t('Common.Save')}
          </CrioButton>
          {!readOnly && (
            <Row className="ClearAndDeleteBtns">
              <div
                role="button"
                tabIndex={0}
                className="LinkButton clearButton"
                onClick={() => clearRecord(recordUid)}
                onKeyDown={() => clearRecord(recordUid)}
              >
                {t('Common.Clear')}
              </div>
              <div
                role="button"
                tabIndex={0}
                className="LinkButton"
                onClick={() => deleteRecord(recordUid)}
                onKeyDown={() => deleteRecord(recordUid)}
              >
                {t('Common.Delete')}
              </div>
            </Row>
          )}
        </Row>
      </TableCell>
    </MultiRecordRow>
  );
}

function SyntheticQuestion({
  dataPoint,
  readOnly,
  isUneditable,
  questions,
}: { dataPoint?: DataPoint, readOnly: boolean, isUneditable: boolean, questions: Array<QuestionInterface> }) {
  const { t } = useTranslation();
  const {
    isLoading,
    records,
    handleAnswerChange,
  } = useContext(ProcedureContext);

  if (!dataPoint) return null;
  const {
    answer,
    answer_type,
    question_name,
  } = dataPoint;
  const questionType = SyntheticQuestionType[dataPoint.answer_type as keyof typeof SyntheticQuestionType];
  const isNoEntry = questionType === SyntheticQuestionType.NO_ENTRY;
  const hasExistingRecords = getDataPointsFromProcedureRecords(records)
    // Don't care about synthetic questions on the procedure itself or unsaved answers
    .filter(({
      answer_id,
      record_id,
    }) => !!answer_id && !!record_id)
    .length > 0;

  // Pull the options from the config
  const answerOptions: Array<Answer> | undefined = (() => {
    const { answerOptions: configAnswerOptions } = questions.find(({ type }) => type === questionType) || {};
    return configAnswerOptions;
  })();
  /* VERY IMPORTANT - THE FIRST OPTION IN THESE SYNTHETIC QUESTIONS IS CONSIDERED YES */
  const positiveAnswer = answerOptions ? answerOptions[0]?.text : undefined;
  const negativeAnswer = answerOptions ? answerOptions[1]?.text : undefined;

  const component = (
    <div data-testid={isNoEntry ? SyntheticQuestionType.NO_ENTRY : SyntheticQuestionType.HAS_CHANGES} className={isUneditable ? 'uneditable' : ''}>
      <ProcedureQuestion
        dataPoint={{
          ...dataPoint,
          answer: (() => {
            if (answer === '0') return negativeAnswer;
            if (answer === '1') return positiveAnswer;
            return answer;
          })(),
        }}
        handleAnswerChange={(answerChange: AnswerChangeOptions) => {
          const { newDataPoint } = answerChange;
          const revisedDataPoint = {
            ...newDataPoint,
            answer_type,
            answer: (() => {
              if (positiveAnswer && newDataPoint.answer === positiveAnswer) return '1';
              if (negativeAnswer && newDataPoint.answer === negativeAnswer) return '0';
              return undefined;
            })(),
          };
          handleAnswerChange({
            ...answerChange,
            newDataPoint: revisedDataPoint,
          });
        }}
        answerOptions={answerOptions}
        isLoading={isLoading}
        questionText={isNoEntry ? t('Procedure.MultiRecord.Are there records') : question_name}
        questionId={questionType}
        order={-1}
        readOnly={readOnly || (isNoEntry && hasExistingRecords)}
        type={NormalQuestionType.SINGLE_SELECT}
      />
    </div>
  );

  if ((isNoEntry && hasExistingRecords)) {
    return (
      <div className="RecordsAlreadyExistWrap">
        <CrioTooltip
          className="RecordsAlreadyExistWarning"
          type="CLICK"
          title={t('Procedure.MultiRecord.Answer Cannot Be Modified')}
        >
          {component}
        </CrioTooltip>
      </div>
    );
  }
  return component;
}

SyntheticQuestion.defaultProps = {
  dataPoint: null,
};

export type MultirecordProps = {
  timeZone?: string,
  isEdc?: boolean,
};

/**
 * Multirecord component that handles rendering multiple Record components.
 * @constructor
 * @param props
 */
export default function MultiRecord({ timeZone, isEdc }: MultirecordProps) {
  const translation = useTranslation();
  const { t } = translation;
  const [expandedRecords, setExpandedRecords] = useState<ExpandedRecordsInterface>({});
  const {
    carryForwardType,
    isLoading,
    isSaving,
    procedureId,
    questions,
    readOnly,
    records,
    rulesProcessing,
    addRecord,
    findSyntheticDataPoint,
    proceedToNextProcedure,
    saveProcedure,
    visitMode,
  } = useContext(ProcedureContext);
  const { visitId } = useParams();
  const isOnLogsPage = !visitId;
  const isPerm = carryForwardType === CarryForwardType.PERMANENT;
  const noEntryDataPoint = findSyntheticDataPoint(SyntheticQuestionType.NO_ENTRY);
  const hasChangesDataPoint = findSyntheticDataPoint(SyntheticQuestionType.HAS_CHANGES);
  const yesThereAreRecords = noEntryDataPoint?.answer === '1';

  const hasChangesDataPointIsAnswered = hasChangesDataPoint?.answer === '1';
  const isUneditable: boolean = readOnly || (!!hasChangesDataPoint && !hasChangesDataPointIsAnswered); // perm procedures by default are readonly
  const [numRecords, setNumRecords] = useState<number>(0);
  const [lastSavedDate, setLastSavedDate] = useState<number | undefined>(undefined);

  useEffect(() => {
    const existingMultiRecords = Object.keys(records)
      .filter((recordId) => recordId !== procedureId);
    const noExistingMultiRecords = !existingMultiRecords || existingMultiRecords.length === 0;
    const { answer: noEntryAnswer } = noEntryDataPoint || {};
    if (noExistingMultiRecords && noEntryAnswer === '1' && !isLoading) {
      // Adding the first record if they selected that there are records
      const newRecordId = generateUids(1)[0];
      addRecord(newRecordId, true);
      setExpandedRecords({ [newRecordId]: true });
    }
    setNumRecords(Object.keys(records)
      .filter((recordId) => recordId !== procedureId).length);
  }, [isLoading, records]);

  useEffect(() => {
    if (numRecords === 0) return;
    const newNumRecords = Object.keys(records)
      .filter((recordId) => recordId !== procedureId).length;
    const lastRecordId = Object.keys(records)[newNumRecords];
    if (
      // if we added a new record
      (newNumRecords > numRecords && !isUneditable)
      // if we checked "Yes", has changes or "No", has no changes
      || (newNumRecords === numRecords)
    ) {
      setExpandedRecords((prevExpandedRecords) => {
        const newExpandedRecords = { ...prevExpandedRecords };
        newExpandedRecords[lastRecordId] = !isUneditable;
        return newExpandedRecords;
      });
    }
    setNumRecords(newNumRecords);
  }, [isUneditable, Object.keys(records).length]);

  const setExpandedCallback = useCallback(((expand: boolean, id: string) => {
    setExpandedRecords((prevExpandedRecords) => {
      const newExpandedRecords = { ...prevExpandedRecords };
      newExpandedRecords[id] = expand;
      return newExpandedRecords;
    });
  }), [records.length]);

  if (isLoading) return null;

  // Collapse the records and render the last modified time
  const handleSaveAndStay = () => {
    setLastSavedDate(new Date().getTime());
    setExpandedRecords({});
  };

  const createRecord = (id: string, index: number) => {
    const filteredRecord = records[id].record;
    const expanded = expandedRecords[id];

    return (
      <Fragment key={id}>
        <RecordPreview
          recordUid={id}
          recordNum={index + 1}
          expanded={!!expanded}
          record={filteredRecord}
          setExpanded={setExpandedCallback}
          questions={questions}
          key={`preview:${id}`}
          recordHash={objectHash(filteredRecord)}
        />
        {expanded && (
          <Record
            recordUid={id}
            recordNum={index + 1}
            questions={questions}
            readOnly={isUneditable}
            record={filteredRecord}
            isLoading={isLoading}
            expanded={expandedRecords[id] || false}
            onSaveCallback={handleSaveAndStay}
            key={`record:${id}`}
          />
        )}
      </Fragment>
    );
  };

  const handleAddAnotherRecord = () => {
    setExpandedRecords({});
    if (yesThereAreRecords) {
      addRecord(generateUids(1)[0]);
    }
  };

  const MainMultirecordComponent = (
    <>
      <div className="HeaderQuestions">
        <SyntheticQuestion questions={questions} dataPoint={noEntryDataPoint} readOnly={readOnly || isUneditable} isUneditable={isUneditable} />
        <div className="TopRight">
          <StyledRequiresTranscriptionToggle>
            { isEdc && carryForwardType === CarryForwardType.PERMANENT && !isAnyProcedureMode(visitMode) && (<RequiresTranscriptionToggle isDisabled={isUneditable} />)}
          </StyledRequiresTranscriptionToggle>
        </div>
      </div>

      <MultiRecordTable>
        <TableHead className="Hideable">
          <TableRow className="TableHeader">
            <MultiRecordHeaderColumn>#</MultiRecordHeaderColumn>
            {
            questions.filter(({ type }) => !isSyntheticQuestionType(type))
              .slice(0, 4)
              .map(
                ({
                  questionId,
                  questionText,
                }: QuestionInterface) => (
                  <MultiRecordHeaderColumn
                    key={questionId}
                  >
                    {questionText}
                  </MultiRecordHeaderColumn>
                ),
              )
          }
            <MultiRecordHeaderColumn />
          </TableRow>
        </TableHead>
        <TableBody>
          {yesThereAreRecords ? Object.keys(records)
            .filter((recordId) => recordId !== procedureId)
            .map((id, index) => createRecord(id, index))
            : (
              <MultiRecordRow>
                <NoRecordsRow colSpan={getBaseColspan(questions) + 1} className="ExpandedRecordNumber">
                  {t('Procedure.MultiRecord.No Records')}
                </NoRecordsRow>
              </MultiRecordRow>
            )}
        </TableBody>
      </MultiRecordTable>
    </>
  );

  const MultirecordContainer = (
    isPerm ? (
      <Folder>
        <FolderTab>
          <span>
            {!isOnLogsPage
              && (
              <div className="iconContainer">
                <FontAwesomeIcon
                  icon={isUneditable ? faLock : faLockOpen}
                  className={`icon ${isUneditable ? 'lockClosed' : 'lockOpen'}`}
                  bounce
                />
              </div>
              )}
            {t('Procedure.MultiRecord.SUBJECT LOGS')}
          </span>
        </FolderTab>
        <FolderPage>
          { MainMultirecordComponent }
          {(!isUneditable && yesThereAreRecords)
            && (
              <div
                role="button"
                tabIndex={0}
                className="LinkButton"
                onClick={handleAddAnotherRecord}
                onKeyDown={handleAddAnotherRecord}
              >
                <FontAwesomeIcon icon={faPlusCircle as IconProp} />
                {' '}
                {t('Procedure.MultiRecord.Add New Record')}
              </div>
            )}
        </FolderPage>
      </Folder>
    ) : (
      <>
        { MainMultirecordComponent }
      </>
    )
  );

  return (
    <StyledDiv isPerm={isPerm} isOnLogsPage={isOnLogsPage}>
      <TableContainer>
        <div>
          <SyntheticQuestion questions={questions} dataPoint={hasChangesDataPoint} readOnly={readOnly} isUneditable={false} />
        </div>

        { MultirecordContainer }

        <MultiRecordProcedureButtons>
          {(() => {
            if (readOnly) {
              if (isOnLogsPage) {
                return null;
              }
              return <CrioButton onClick={() => proceedToNextProcedure()}>{t('Common.Continue')}</CrioButton>;
            }

            if (isOnLogsPage) {
              return (
                <CrioLoadingButton
                  loading={isSaving}
                  onClick={() => {
                    saveProcedure({
                      successCallback: handleSaveAndStay,
                    });
                  }}
                >
                  {t('Common.Finish')}
                </CrioLoadingButton>
              );
            }
            return (
              <CrioButton onClick={() => {
                saveProcedure({ successCallback: proceedToNextProcedure });
              }}
              >
                {t('Common.Save and Continue')}
              </CrioButton>
            );
          })()}
          {!!rulesProcessing && <span data-testid="rulesProcessing" />}
          {(!isUneditable && yesThereAreRecords && !isPerm)
            && (
              <div
                role="button"
                tabIndex={0}
                className="LinkButton"
                onClick={handleAddAnotherRecord}
                onKeyDown={handleAddAnotherRecord}
              >
                <FontAwesomeIcon icon={faPlusCircle as IconProp} />
                {' '}
                {t('Procedure.MultiRecord.Add New Record')}
              </div>
            )}
        </MultiRecordProcedureButtons>
        {!!lastSavedDate
          && <LastSaveText>{`Saved on ${formatDateTimeWithTranslation(lastSavedDate, translation, timeZone)}`}</LastSaveText>}
      </TableContainer>
    </StyledDiv>
  );
}

MultiRecord.defaultProps = {
  timeZone: 'US/Eastern',
};
