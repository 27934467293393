import { useMemo } from 'react';
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getAuth } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import {
  EconsentContactType, isAnySandboxMode, VisitModeType,
} from '../enums';
import {
  DataPoint, Lock, ProgressNoteInterface, SubjectData, VisitInterface,
} from '../types';
import { fetchRequest } from '../util/apiUtil';
import getDomain from './getDomain';
import getIndexedDbConnection from '../util/indexedDb';
import ProcedureStatusInterface from '../types/ProcedureStatusInterface';
import SaveDataPointsResponse from './types/SaveDataPointsResponse';
import EconsentData from '../types/EconsentData';
import { addProcedureLockCookie, removeProcedureLockCookie } from './cookies';
import AutoQuery from '../types/AutoQuery';
import { partitionDataPointsBySpecificity } from '../util/dataPointUtil';
import { formatTimestamp } from '../util/dateTimeUtil';
import SubjectUpdateRequest from '../types/SubjectUpdateRequest';

const urlParams = new URLSearchParams(window.location.search);
const esourceServiceUrl = (() => getDomain(urlParams.get('region')))();

const handleResponse = async (response: Response, defaultError: string | null) => {
  let json: any = {};
  try {
    json = await response.json();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error received from server: ', e);
  }
  if (response.ok) {
    return json;
  }
  const error = json?.message || JSON.stringify(json);
  throw new Error(defaultError || error);
};

function extractError(response: UseQueryResult<any, unknown>) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return response.error && response.error.message;
}

const verifyVisitConfig = (visitConfig: VisitInterface | any) => {
  if (!visitConfig || Object.keys(visitConfig).length === 0) {
    throw new Error('Invalid visit configuration.');
  }

  if (!visitConfig.visitId && (!visitConfig.procedures || visitConfig.procedures.length === 0)) {
    throw new Error('On logs page, but there are no permanent procedures.');
  }
};

const _fetchProgressNotes = async (visitMode: VisitModeType | undefined, studyId: string, subjectId?: string, visitId?: string, procedureId?: string): Promise<ProgressNoteInterface[]> => {
  if (!visitId || VisitModeType.SUBJECT === visitMode) {
    // Logs Mode or Subject Mode
    return Promise.resolve([]);
  }

  if (!subjectId) {
    // Sandbox Mode
    const db = await getIndexedDbConnection('Sandbox');
    if (!procedureId) {
      // Fetch visit-level progress notes with a fake procedureId in sandbox mode.
      return db.getProgressNotes('visit');
    }

    return db.getProgressNotes(procedureId!);
  }

  // Default Mode
  let queryUrl = `${esourceServiceUrl}/progressNote/studies/${studyId}/subjects/${subjectId}/visits/${visitId}`;
  if (procedureId) {
    queryUrl += `/procedures/${procedureId}`;
  }
  const response = await fetchRequest({ endpoint: queryUrl, method: 'GET' });
  return handleResponse(response!, null);
};

const useFetchProgressNotesQuery = (visitMode: VisitModeType | undefined, studyId: string, subjectId?: string, visitId?: string, procedureId?: string) => {
  const response = useQuery(['fetchProgressNotes', procedureId], () => _fetchProgressNotes(visitMode, studyId, subjectId, visitId, procedureId), {
    networkMode: subjectId ? 'always' : 'online',
    refetchOnMount: false,
    enabled: !!visitId,
  });

  return useMemo(
    () => ({
      progressNotes: response.data as ProgressNoteInterface[],
      ...response,
      errorMessage: extractError(response),
    }),
    [response],
  );
};

const fetchProcedureStatuses = async (studyId: string, subjectId?: string, visitId?: string): Promise<Array<ProcedureStatusInterface>> => {
  if (!subjectId) {
    // Sandbox Mode
    const db = await getIndexedDbConnection('Sandbox');
    return await db.getProcedureStatuses() || [];
  }

  let url;
  if (!visitId) {
    // Logs Mode
    url = `${esourceServiceUrl}/procedureStatus/studies/${studyId}/subjects/${subjectId}`;
  } else {
    // Default Mode
    url = `${esourceServiceUrl}/procedureStatus/studies/${studyId}/subjects/${subjectId}/visits/${visitId}`;
  }

  // Default Mode
  const response = await fetchRequest({ endpoint: url, method: 'GET' });
  return handleResponse(response!, null);
};

const fetchSubjectVisitConfig = async (visitMode: VisitModeType | undefined, studyId: string, subjectId?: string, visitId?: string, procedureId?: string, procedureTemplateId?: string): Promise<VisitInterface> => {
  let url;
  let pathPrefix = 'config';
  if (VisitModeType.SUBJECT === visitMode) {
    pathPrefix = 'subjectconfig';
  }

  if (procedureTemplateId) {
    // Sandbox Procedure Template Mode
    url = `${esourceServiceUrl}/${pathPrefix}/studies/${studyId}/procedureTemplates/${procedureTemplateId}`;
  } else if (!subjectId && !visitId) {
    // Sandbox Procedure Mode
    url = `${esourceServiceUrl}/${pathPrefix}/studies/${studyId}/procedures/${procedureId}`;
  } else if (!subjectId) {
    // Sandbox Mode
    url = `${esourceServiceUrl}/${pathPrefix}/studies/${studyId}/visits/${visitId}`;
  } else if (!visitId) {
    // Logs Mode
    url = `${esourceServiceUrl}/${pathPrefix}/studies/${studyId}/subjects/${subjectId}`;
  } else {
    // Default Mode
    url = `${esourceServiceUrl}/${pathPrefix}/studies/${studyId}/subjects/${subjectId}/visits/${visitId}`;
  }

  const response = await fetchRequest({ endpoint: url, method: 'GET' });
  const visitConfig = await handleResponse(response!, null);
  visitConfig.fetchTime = new Date().getTime();

  return visitConfig;
};

interface FetchDataPointsInterface {
  visitMode: VisitModeType | undefined,
  studyId: string,
  subjectId?: string,
  visitId?: string,
  procedureId: string,
}
const fetchDataPoints = async ({
  visitMode, studyId, subjectId, visitId, procedureId,
}: FetchDataPointsInterface): Promise<Array<DataPoint>> => {
  if (isAnySandboxMode(visitMode)) {
    // Sandbox Mode
    const db = await getIndexedDbConnection('Sandbox');
    return await db.getDataPoints(procedureId) || [];
  }

  let url;
  if (visitId) {
    // Default Mode
    url = `${esourceServiceUrl}/dataPoint/studies/${studyId}/subjects/${subjectId}/visits/${visitId}/procedures/${procedureId}`;
  } else {
    // Logs Mode
    url = `${esourceServiceUrl}/dataPoint/studies/${studyId}/subjects/${subjectId}/procedures/${procedureId}?excludeVisitLevel=1`;
  }

  const response = await fetchRequest({ endpoint: url, method: 'GET' });
  return handleResponse(response!, null);
};

interface FetchDataPointsInterface {
  visitMode: VisitModeType | undefined,
  studyId: string,
  subjectId?: string,
  visitId?: string,
  procedureId: string,
}
const fetchReferenceDataPoints = async ({
  visitMode, studyId, subjectId, visitId, procedureId,
}: FetchDataPointsInterface): Promise<Array<DataPoint>> => {
  // Don't attempt to load reference questions in sandbox mode or on the logs page.
  if (isAnySandboxMode(visitMode) || !visitId) {
    return [];
  }

  const url = `${esourceServiceUrl}/dataPoint/studies/${studyId}/subjects/${subjectId}/visits/${visitId}/procedures/${procedureId}/reference`;

  const response = await fetchRequest({ endpoint: url, method: 'GET' });
  return handleResponse(response!, null);
};

export interface LockRequestInterface {
  studyId: string,
  subjectId?: string,
  visitId?: string,
  procedureId: string,
  lock?: Lock,
}
const fetchLock = async ({
  subjectId, studyId, procedureId, visitId,
}: LockRequestInterface): Promise<Lock> => {
  let url;

  if (!subjectId && visitId) {
    // Sandbox Mode
    const { currentUser } = getAuth();
    const { uid } = currentUser || {};

    return Promise.resolve({
      acquireTime: new Date().getTime(),
      userId: uid,
      lockMessage: 'Sandbox mode, no real locks',
    });
  } if (visitId) {
    // Default Mode
    url = `${esourceServiceUrl}/lock/studies/${studyId}/subjects/${subjectId}/visits/${visitId}/procedures/${procedureId}`;
  } else {
    // Logs Mode
    url = `${esourceServiceUrl}/lock/studies/${studyId}/subjects/${subjectId}/procedures/${procedureId}`;
  }

  const response = await fetchRequest({ endpoint: url, method: 'GET' });
  return handleResponse(response!, null);
};

const _transformLockUrlToCookieKey = (url: string) => url.substring(url.indexOf('lock') + 4, url.length);

const acquireLock = async ({
  subjectId, studyId, procedureId, visitId, lock,
}: LockRequestInterface): Promise<Lock> => {
  if (!lock) {
    return Promise.resolve({
      lockMessage: 'Failed to aquire lock, malformed request',
    });
  }

  let url;
  if (!subjectId) {
    // Sandbox Mode
    const { currentUser } = getAuth();
    const { uid } = currentUser || {};

    return Promise.resolve({
      acquireTime: new Date().getTime(),
      userId: uid,
      lockMessage: 'Sandbox mode, no real locks',
    });
  } if (visitId) {
    // Default Mode
    url = `${esourceServiceUrl}/lock/studies/${studyId}/subjects/${subjectId}/visits/${visitId}/procedures/${procedureId}`;
  } else {
    // Logs Mode
    url = `${esourceServiceUrl}/lock/studies/${studyId}/subjects/${subjectId}/procedures/${procedureId}`;
  }

  const response = await fetchRequest({ endpoint: url, method: 'POST', body: lock });
  const acquiredLock = await handleResponse(response!, null);
  addProcedureLockCookie(_transformLockUrlToCookieKey(url), acquiredLock.expireTime);

  return acquiredLock;
};

const deleteLock = async ({
  subjectId, studyId, procedureId, visitId,
}: LockRequestInterface): Promise<Lock> => {
  let url;
  if (!subjectId) {
    // Sandbox Mode
    return Promise.resolve({ lockMessage: 'Sandbox mode, no locks' });
  } if (visitId) {
    // Default Mode
    url = `${esourceServiceUrl}/lock/studies/${studyId}/subjects/${subjectId}/visits/${visitId}/procedures/${procedureId}`;
  } else {
    // Logs Mode
    url = `${esourceServiceUrl}/lock/studies/${studyId}/subjects/${subjectId}/procedures/${procedureId}`;
  }

  const locksHeld = removeProcedureLockCookie(_transformLockUrlToCookieKey(url));
  if (locksHeld <= 0) {
    // We need to set keepalive to true so the page reload does not cancel our request on unload
    const response = await fetchRequest({ endpoint: url, method: 'DELETE', keepalive: true });
    return handleResponse(response!, null);
  }
  return { lockMessage: 'Not completely deleting lock because user has lock in another tab' };
};

/**
 * Fetches previous data points, so visitId here represents the
 * visit that we want to exclude
 */
const fetchPreviousDataPoints = async ({
  visitMode, studyId, subjectId, visitId, procedureId,
}: FetchDataPointsInterface): Promise<Array<DataPoint>> => {
  // There is no notion of previous data points if we are in sandbox mode
  if (isAnySandboxMode(visitMode) || !subjectId || !studyId || !visitId) {
    return [];
  }

  const response = await fetchRequest({
    endpoint: `${esourceServiceUrl}/dataPoint/studies/${studyId}/subjects/${subjectId}/procedures/${procedureId}?excludeVisit=${visitId}`,
    method: 'GET',
  });
  return handleResponse(response!, null);
};

interface SaveDataPointsProps {
  dataPointsToSave: DataPoint[],
  studyId: string,
  visitId?: string,
  subjectId?: string,
  procedureId?: string,
}
const saveDataPoints = async ({
  dataPointsToSave, studyId, visitId, subjectId, procedureId,
}: SaveDataPointsProps): Promise<SaveDataPointsResponse> => {
  // Don't waste a server call if there's nothing to save
  if (!dataPointsToSave || dataPointsToSave.length === 0) {
    return Promise.resolve({
      success: [],
      fail: [],
    });
  }

  console.log(dataPointsToSave);

  if (!subjectId && visitId) {
    // Sandbox Mode
    try {
      const db = await getIndexedDbConnection('Sandbox');
      const savedDataPoints = await db.addDataPoints(dataPointsToSave);
      await db.addProcedureStatuses([{
        procedureId: dataPointsToSave[0].procedure_id,
        status: dataPointsToSave[0].procedure_status,
      }]);
      return {
        success: savedDataPoints,
        fail: [],
      };
    } catch (err) {
      return {
        success: [],
        fail: dataPointsToSave,
      };
    }
  }

  const result: SaveDataPointsResponse = {
    success: [],
    fail: [],
  };
  const { procedureLevelDataPoints, visitLevelDataPoints } = partitionDataPointsBySpecificity(dataPointsToSave);
  const VISIT_DATAPOINTS_URL = `${esourceServiceUrl}/dataPoint/studies/${studyId}/subjects/${subjectId}/visits/${visitId}`;
  const PROCEDURE_DATAPOINTS_URL = (() => {
    if (visitId) {
      // Saving procedure-level datapoints in a visit
      return `${esourceServiceUrl}/dataPoint/studies/${studyId}/subjects/${subjectId}/visits/${visitId}/procedures/${procedureId}`;
    }
    // Saving procedure-level datapoints on the logs page
    return `${esourceServiceUrl}/dataPoint/studies/${studyId}/subjects/${subjectId}/procedures/${procedureId}`;
  })();
  const attemptSave = async (dataPoints: DataPoint[], url: string) => {
    try {
      const response = await fetchRequest({
        endpoint: url,
        method: 'POST',
        body: dataPoints,
      });
      const json: SaveDataPointsResponse = await handleResponse(response!, null) as SaveDataPointsResponse;
      result.success = json.success instanceof Array ? result.success.concat(json.success) : [];
      result.fail = json.fail instanceof Array ? result.fail.concat(json.fail) : [];
    } catch (error) {
      console.error(error);
      result.fail = result.fail.concat(dataPoints);
    }
  };

  if (procedureLevelDataPoints.length > 0) {
    await attemptSave(procedureLevelDataPoints, PROCEDURE_DATAPOINTS_URL);
  }

  if (visitLevelDataPoints.length > 0) {
    await attemptSave(visitLevelDataPoints, VISIT_DATAPOINTS_URL);
  }

  return result;
};

const _saveProgressNote = async (studyId: string, subjectId: string, visitId: string, procedureId: string, visitMode: VisitModeType | undefined, progressNote: ProgressNoteInterface): Promise<ProgressNoteInterface> => {
  if (visitMode === VisitModeType.SANDBOX) {
    const db = await getIndexedDbConnection('Sandbox');
    if (!procedureId) {
      // Save visit-level progress notes with a fake procedureId in sandbox mode.
      progressNote.procedure_id = 'visit';
    }
    return db.addProgressNote(progressNote);
  }

  if (visitMode === VisitModeType.DEFAULT) {
    let queryUrl = `${esourceServiceUrl}/progressNote/studies/${studyId}/subjects/${subjectId}/visits/${visitId}`;
    if (procedureId) {
      queryUrl += `/procedures/${procedureId}`;
    }
    const response = await fetchRequest({ endpoint: queryUrl, method: 'POST', body: progressNote });
    return handleResponse(response!, null);
  }

  return Promise.reject(new Error("You must be in a read-only mode, this shouldn't happen, please contact someone at CRIO!"));
};

const useSaveProgressNoteMutation = () => useMutation(
  (variables:
  {
    studyId: string,
    subjectId: string,
    visitId: string,
    procedureId: string,
    visitMode: VisitModeType | undefined,
    progressNote: ProgressNoteInterface
  }) => _saveProgressNote(variables.studyId, variables.subjectId, variables.visitId, variables.procedureId, variables.visitMode, variables.progressNote),
  {
    networkMode: 'always',
  },
);

const _deleteProgressNote = async (studyId: string, subjectId: string, visitMode: VisitModeType | undefined, progressNoteExternalId: string): Promise<void> => {
  if (visitMode === VisitModeType.SANDBOX) {
    const db = await getIndexedDbConnection('Sandbox');
    return db.deleteProgressNote(progressNoteExternalId);
  }

  if (visitMode === VisitModeType.DEFAULT) {
    const queryUrl = `${esourceServiceUrl}/progressNote/studies/${studyId}/subjects/${subjectId}/progressNotes/${progressNoteExternalId}`;
    const response = await fetchRequest({ endpoint: queryUrl, method: 'DELETE' });
    const responseBody = await handleResponse(response!, null);
    // If we fail to delete progress note on the back end, we will receive null
    if (responseBody === null) return Promise.reject(new Error('There was an issue deleting the progress note!'));
    return Promise.resolve();
  }

  return Promise.reject(new Error("You must be in a read-only mode, this shouldn't happen, please contact someone at CRIO!"));
};

const useDeleteProgressNoteMutation = () => useMutation(
  (variables:
  {
    studyId: string,
    subjectId: string,
    visitMode: VisitModeType | undefined,
    progressNoteExternalId: string,
  }) => _deleteProgressNote(variables.studyId, variables.subjectId, variables.visitMode, variables.progressNoteExternalId),
  {
    networkMode: 'always',
  },
);

interface SaveAutoQueriesProps {
  autoQueriesToSave: Array<AutoQuery>,
  studyId: string,
  subjectId?: string,
}
const saveAutoQueries = async ({ autoQueriesToSave, studyId, subjectId }: SaveAutoQueriesProps): Promise<Array<AutoQuery>> => {
  // Don't waste a server call if there's nothing to save
  console.log(autoQueriesToSave);
  if (!autoQueriesToSave || autoQueriesToSave.length === 0 || !subjectId) {
    return Promise.resolve([]);
  }

  const response = await fetchRequest({
    endpoint: `${esourceServiceUrl}/autoQuery/studies/${studyId}/subjects/${subjectId}`,
    method: 'POST',
    body: autoQueriesToSave,
  });
  return handleResponse(response!, null);
};

/**
 * Fetch the Data List for a given Study Procedure Question
 * @param studyId     String containing the Study External ID
 * @param dataListId  String containing the Data List External ID
 * @param searchTerm  String containing the search term
 * @return            Promise<DataList | null> containing the Data List (if there is one for this Question)
 */
const fetchDataList = async (studyId: string, dataListId: string, searchTerm: string) => {
  const response = await fetchRequest({
    endpoint: `${esourceServiceUrl}/dataList/studies/${studyId}/dataLists/${dataListId}?searchTerm=${searchTerm}`,
    method: 'GET',
  });
  return handleResponse(response!, null);
};

const _fetchSubjectData = async (studyId: string, subjectId?: string, visitId?: string): Promise<SubjectData> => {
  let url;

  if (!subjectId) {
    // Sandbox
    url = `${esourceServiceUrl}/subjectMetaData/studies/${studyId}`;
  } else if (visitId) {
    // Default Mode
    url = `${esourceServiceUrl}/subjectMetaData/studies/${studyId}/subjects/${subjectId}?visitId=${visitId}`;
  } else {
    // Logs Mode
    url = `${esourceServiceUrl}/subjectMetaData/studies/${studyId}/subjects/${subjectId}`;
  }

  const response = await fetchRequest({ endpoint: url, method: 'GET' });
  return handleResponse(response!, null);
};

/**
 * Fetch the subject data
 * @param studyId
 * @param subjectId
 * @param visitId
 * @returns The subject data
 */
const useFetchSubjectData = (studyId: string, subjectId?: string, visitId?: string) => {
  const { i18n } = useTranslation();
  const response = useQuery(['fetchSubjecctData', studyId], async () => {
    const subjectMetaData = await _fetchSubjectData(studyId, subjectId, visitId);
    const { languageCode } = subjectMetaData;
    if (languageCode) {
      i18n.changeLanguage(languageCode);
    }
    return subjectMetaData;
  }, {
    refetchOnMount: false,
    staleTime: Infinity,
    refetchOnWindowFocus: 'always',
  });

  const fetchedData = response.data as SubjectData;
  const { startTime: fetchedStartTime, timeZone } = fetchedData || {};
  const subjectData = {
    ...fetchedData,
    startTime: (typeof fetchedStartTime === 'number') ? formatTimestamp(fetchedStartTime, i18n.language, timeZone || 'US/Eastern') : fetchedStartTime || '',
    subjectId: subjectId!,
  };

  return useMemo(
    () => ({
      subjectData,
      ...response,
      errorMessage: extractError(response),
    }),
    [response],
  );
};

const _fetchEconsentData = async (studyId: string, subjectId: string, visitId: string, procedureId: string) => {
  const queryUrl = `${esourceServiceUrl}/econsent/studies/${studyId}/subjects/${subjectId}/visits/${visitId}/procedures/${procedureId}`;
  const response = await fetchRequest({ endpoint: queryUrl, method: 'GET' });
  return handleResponse(response, null);
};

const useFetchEconsentDataQuery = (studyId: string, subjectId: string, visitId: string, procedureId: string, enabled?: boolean) => {
  const response = useQuery(['fetchEconsentData', studyId], () => _fetchEconsentData(studyId, subjectId, visitId, procedureId), {
    enabled,
    refetchOnMount: false,
  });

  return useMemo(
    () => ({
      econsentData: response.data as EconsentData,
      ...response,
      errorMessage: extractError(response),
    }),
    [response],
  );
};

interface PinCorrespondenceConfig {
  sendSignedLink: boolean,
  contactType: EconsentContactType,
}

/**
 * Fetch the eConsent PIN Data
 * @param studyId      string containing the Study ID
 * @param subjectId    string containing the Subject ID
 * @param visitId      string containing the Visit ID
 * @param procedureId  string containing the Procedure ID
 */
const sendPinData = async (studyId: string, subjectId: string, visitId: string, procedureId: string, config: PinCorrespondenceConfig) => {
  const queryUrl = `${esourceServiceUrl}/econsent/studies/${studyId}/subjects/${subjectId}/visits/${visitId}/procedures/${procedureId}/pins`;
  const response = await fetchRequest({ endpoint: queryUrl, method: 'POST', body: config });
  return handleResponse(response, null);
};

/**
 * Fetch the internal eConsent link
 * @param studyId      string containing the Study ID
 * @param subjectId    string containing the Subject ID
 * @param visitId      string containing the Visit ID
 * @param procedureId  string containing the Procedure ID
 */
const fetchInternalEconsentLink = async (studyId: string, subjectId: string, visitId: string, procedureId: string) => {
  const queryUrl = `${esourceServiceUrl}/econsent/studies/${studyId}/subjects/${subjectId}/visits/${visitId}/procedures/${procedureId}/internal-link`;
  const response = await fetchRequest({ endpoint: queryUrl, method: 'GET' });
  return handleResponse(response, null);
};

/**
 * Update subject meta data
 * @param studyId      string containing the Study ID
 * @param subjectId    string containing the Subject ID
 * @param request      the request body
 * @param visitId      string containing the Visit ID
 */
const updateSubject = async (studyId: string, subjectId: string, request: SubjectUpdateRequest, visitId?: string) => {
  let url;
  if (visitId) {
    // Default Mode
    url = `${esourceServiceUrl}/subjectMetaData/studies/${studyId}/subjects/${subjectId}?visitId=${visitId}`;
  } else {
    // Logs Mode
    url = `${esourceServiceUrl}/subjectMetaData/studies/${studyId}/subjects/${subjectId}`;
  }
  const response = await fetchRequest({ endpoint: url, method: 'POST', body: [request] });
  return handleResponse(response, null);
};

export {
  acquireLock,
  deleteLock,
  fetchDataPoints,
  fetchReferenceDataPoints,
  fetchLock,
  fetchPreviousDataPoints,
  fetchProcedureStatuses,
  useFetchProgressNotesQuery,
  fetchSubjectVisitConfig,
  verifyVisitConfig,
  fetchDataList,
  saveDataPoints,
  saveAutoQueries,
  sendPinData,
  useSaveProgressNoteMutation,
  useDeleteProgressNoteMutation,
  useFetchEconsentDataQuery,
  fetchInternalEconsentLink,
  useFetchSubjectData,
  updateSubject,
};
