import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import { CrioCheckbox } from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioCheckboxGroup';
import CrioTextField from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioTextField';
import {
  Fragment, useContext, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import styled from 'styled-components';
import { NotDoneRecordsInterface, SimplifiedRecords } from '../../../../context/types';
import { QuestionInterface } from '../../../../types';
import SaveAlertContext from '../../../../context/SaveAlertContext';
import MissingAnswersResolutionType from '../../../../enums/MissingAnswersResolutionType';
import { SyntheticQuestionType } from '../../../../enums';

export interface MarkNotDoneDialogProps {
  closeHandler?: Function,
  completeHandler?: Function,
  missingAnswers: SimplifiedRecords,
  multipleAlerts: boolean,
  nothingEverAnswered: boolean,
}

const StyledDiv = styled.div`
  font-family: 'Poppins Light', 'Poppins', 'Poppins', sans-serif;
  padding-top: 10px;

  .content {
    margin: 30px;
    margin-top: 15px;
    margin-bottom: 0;
  }

  #checkIcon {
    color: #fff;
    margin: 2px auto;
  }

  .markNotDoneCheckbox {
    font-weight: 300;
    font-size: 14px;
  }

  .commentInput {
    margin: 10px;
    width: 100%;
  }

  .copy {
    font-family: "Poppins Light", "Poppins", sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #333333;
    padding-top: 10px;
  }

  .recordHeading {
    margin-top: 15px;
    font-weight: 400;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    margin-top: 15px;
    .continueButton {
      padding-left: 0 !important;
      cursor: pointer;
      margin-right: 10px;
      &:hover {
        color: #C04040;
      }
    }
  }

  .errorMessage {
    color: #c04040;
    margin-left: 10px;
  }
`;

function MarkNotDone({
  missingAnswers = [],
  completeHandler = () => { },
  closeHandler = () => { },
  multipleAlerts = false,
  nothingEverAnswered,
}: MarkNotDoneDialogProps) {
  const { t } = useTranslation();
  const {
    notDoneComment,
    notDoneError,
    notDoneQueuedChanges,
    setMissingAnswersResolutionType,
    setNotDoneComment,
    setNotDoneError,
    setNotDoneQueuedChanges,

  } = useContext(SaveAlertContext);

  useEffect(() => {
    setNotDoneQueuedChanges((prevState: NotDoneRecordsInterface) => {
      const newState = { ...prevState };
      missingAnswers.forEach(({ recordId, questions }) => {
        newState[recordId] = {};
        questions.forEach(({ questionId }: QuestionInterface) => {
          newState[recordId][questionId] = true;
        });
      });

      return newState;
    });
  }, [missingAnswers]);

  return (
    <StyledDiv>
      {!nothingEverAnswered && (
        <div className="copy">
          {t('Procedure.Dialog.Mark Not Done.Copy')}
          {' '}
          <b>{t('Procedure.Dialog.Mark Not Done.NOT DONE')}</b>
        </div>
      )}
      <div className="content">
        {/* Print out the list of missing questions for each record */}
        {missingAnswers.map(({ recordId, recordIndex, questions = [] }) => (
          <Fragment key={`${recordId}:${recordIndex}`}>
            {recordIndex && (<div className="recordHeading">{`Record ${recordIndex}`}</div>)}

            {questions.map(({ questionText, questionId, type }) => {
              const text = type === SyntheticQuestionType.NO_ENTRY ? t('Procedure.MultiRecord.Are there records') : questionText;
              return (
                <div
                  key={`${recordId}-${questionId}`}
                  className="markNotDoneCheckbox"
                >
                  <CrioCheckbox
                    disabled={nothingEverAnswered}
                    name={questionId}
                    onChange={() => {
                      setNotDoneError('');
                      setNotDoneQueuedChanges((prevState: any) => {
                        const newState = JSON.parse(JSON.stringify(prevState));
                        const target = newState[recordId][questionId];
                        newState[recordId][questionId] = !target;

                        return newState;
                      });
                    }}
                    checked={!!notDoneQueuedChanges[recordId]?.[questionId]}
                  />
                  <span>{text}</span>
                </div>
              );
            })}
          </Fragment>
        ))}
        <CrioTextField
          className="commentInput"
          multiline
          placeholder={t('Common.Enter Comments')}
          rows={4}
          onChange={(e) => setNotDoneComment(e.target.value)}
          value={notDoneComment}
          onFocus={() => setNotDoneError('')}
        />

        {notDoneError && (
          <span className="errorMessage">{notDoneError}</span>
        )}

        <div className="buttonsContainer">
          <div data-qa-id="continue" className="continueButton">
            <CrioButton
              colorTheme="Primary"
              onClick={() => {
                completeHandler({ skipMissingAnswers: false });
              }}
            >
              {multipleAlerts ? <CheckIcon id="checkIcon" /> : 'Save'}
            </CrioButton>
          </div>
          <div data-qa-id="cancel">
            <CrioButton
              colorTheme="Secondary"
              onClick={() => {
                setMissingAnswersResolutionType(MissingAnswersResolutionType.SKIPPED);
                closeHandler();
              }}
            >
              {t('Common.Cancel')}
            </CrioButton>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
}

MarkNotDone.defaultProps = {
  closeHandler: () => { },
  completeHandler: () => { },
};

export default MarkNotDone;
