import CrioTextField from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioTextField';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import styled from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from '..';
import ProgressNoteStatus from '../../enums/ProgressNoteStatus';
import ProgressNoteContext from '../../context/ProgressNoteContext';
import { SlideoutState } from '../../enums';
import { ProgressNoteInterface } from '../../types';
import { formatDateTime } from '../../util/dateTimeUtil';

const StyledAddProcedureProgressNote = styled.div`
  padding-right: 10px;

  .MuiTextField-root {
    padding-bottom: 10px;
    width: 100%;

    textarea {
      font-size: .75rem;
    }
  }

  button {
    margin: 5px 0;
    width: 100%;
    font-weight: 500;
  }

  .DraftNote {
    align-items: center;
    padding: 10px 0;

    .FileIcon {
      font-size: 1.6em;
      padding-right: 10px;

      &.Draft {
        color: #b1e3ff;
      }

      &.Published {
        color: ${(props) => props.theme.palette.info.dark};
      }
    }

    span {
      color: ${(props) => props.theme.palette.grey[700]};
      font-style: italic;
      font-size: .75rem;
    }
  }

  .DeleteDraft {
    color: ${(props) => props.theme.palette.grey[600]};
    text-align: center;
    font-size: .9rem;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    margin-top: 3%;
    :hover {
      cursor: pointer;
      color: ${(props) => props.theme.palette.error.main};
    }
  }

  .ESignature {
    padding-bottom: 25px;

    a {
      color: ${(props) => props.theme.palette.linkText.main};
      text-decoration: none;
      font-size: .75rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

type AddProcedureProgressNoteProps = {
  progressNoteToEdit: ProgressNoteInterface | undefined;
  handleSlideoutStateChange: (slideoutState: SlideoutState) => void;
  closeSlideoutOnSave?: () => void;
  timeZone?: string;
};

export default function AddEditProgressNote({
  progressNoteToEdit,
  handleSlideoutStateChange,
  closeSlideoutOnSave,
  timeZone,
}: AddProcedureProgressNoteProps) {
  const { t } = useTranslation();
  const dateStarted = progressNoteToEdit === undefined ? DateTime.now() : DateTime.fromMillis(progressNoteToEdit.date_created);
  const [progressNoteText, setProgressNoteText] = useState<string>(progressNoteToEdit === undefined ? '' : progressNoteToEdit.note);
  const {
    handleCreateProgressNote, handleUpdateProgressNote, handleDeleteProgressNote,
  } = useContext(ProgressNoteContext);
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgressNoteText(e.target.value);
  };
  const updateSlideout = () => {
    if (closeSlideoutOnSave) closeSlideoutOnSave();
    else handleSlideoutStateChange(SlideoutState.VIEW_PROGRESS_NOTES);
  };

  const handleSaveButton = () => {
    if (progressNoteToEdit === undefined) {
      handleCreateProgressNote(ProgressNoteStatus.DRAFT, progressNoteText, true, updateSlideout);
    } else {
      handleUpdateProgressNote(progressNoteToEdit, progressNoteText, undefined, updateSlideout);
    }
  };

  const handlePublishButton = () => {
    if (progressNoteToEdit === undefined) {
      handleCreateProgressNote(ProgressNoteStatus.PUBLISHED, progressNoteText, true, updateSlideout);
    } else {
      handleUpdateProgressNote(progressNoteToEdit, progressNoteText, ProgressNoteStatus.PUBLISHED, updateSlideout);
    }
  };

  const handleDeleteButton = () => {
    // progressNoteToEdit must be defined if we can trigger delete button, so fine to assert
    handleDeleteProgressNote(progressNoteToEdit!, updateSlideout);
  };

  // This useEffect is needed to properly update the progressNoteText when going directly from Edit workflow to Add workflow
  useEffect(() => {
    setProgressNoteText(progressNoteToEdit === undefined ? '' : progressNoteToEdit.note);
  }, [progressNoteToEdit]);

  return (
    <StyledAddProcedureProgressNote>
      <CrioTextField
        multiline
        minRows={4}
        maxRows={10}
        value={progressNoteText}
        onChange={handleTextChange}
        placeholder={t('Procedure.Dialog.Missing Answers.Resolutions.Enter Progress Note')}
      />
      <Row className="DraftNote">
        <FontAwesomeIcon
          icon={faFileAlt as IconProp}
          className={`FileIcon ${progressNoteToEdit === undefined || progressNoteToEdit.status === ProgressNoteStatus.DRAFT ? 'Draft' : 'Published'}`}
        />
        <span>
          {progressNoteToEdit === undefined || progressNoteToEdit.status === ProgressNoteStatus.DRAFT ? t('Procedure.Progress Note.draft') : t('Procedure.Progress Note.note')}
          {` started on ${formatDateTime(dateStarted.toMillis(), timeZone)}`}
        </span>
      </Row>
      <CrioButton
        onClick={handleSaveButton}
        data-testid="note-save-draft-button"
      >
        {progressNoteToEdit !== undefined ? t('Common.Save') : t('Procedure.Progress Note.Save Draft')}
      </CrioButton>
      {/* If we are creating a new note or editing a draft note */}
      {(progressNoteToEdit === undefined || progressNoteToEdit.status === ProgressNoteStatus.DRAFT)
        && (
          <CrioButton
            onClick={handlePublishButton}
            data-testid="note-publish-button"
          >
            {t('Common.Publish')}
          </CrioButton>
        )}
      <CrioButton
        colorTheme="Secondary"
        onClick={updateSlideout}
        data-testid="note-cancel-button"
      >
        {t('Common.Cancel')}
      </CrioButton>
      {/* If we are editing a draft note */}
      {progressNoteToEdit !== undefined && progressNoteToEdit.status === ProgressNoteStatus.DRAFT
        && (
          <div
            className="DeleteDraft"
            role="button"
            tabIndex={0}
            onClick={handleDeleteButton}
            onKeyDown={handleDeleteButton}
          >
            {t('Procedure.Progress Note.Delete Draft Note')}
          </div>
        )}
    </StyledAddProcedureProgressNote>
  );
}
AddEditProgressNote.defaultProps = {
  closeSlideoutOnSave: undefined,
  timeZone: 'US/Eastern',
};
