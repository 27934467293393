import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import CrioTextField from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioTextField';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import RadioGroup from '@crio/crio-react-component/dist/cjs/components/Inputs/RadioGroup';
import { RadioOption } from '@crio/crio-react-component/dist/cjs/components/Inputs/RadioGroup/RadioGroup';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import { Stack } from '@mui/material';
import SaveAlertContext from '../../../../context/SaveAlertContext';
import Column from '../../../Column';
import ProgressNoteStatus from '../../../../enums/ProgressNoteStatus';

const StyledDiv = styled.div`
  font-family: 'Poppins Light', 'Poppins', 'Poppins', sans-serif;

  #checkIcon {
    color: #fff;
    margin: 2px auto;
  }

  #buttonsContainer {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-top: 15px;
    .continueButton {
      padding-left: 0 !important;
      cursor: pointer;
      margin-right: 10px;
      &:hover {
        color: #C04040;
      }
    }
  }

  .publishOptions span {
    font-size: 1em;
  }

  .draft-text {
    font-size: 14px;
    color: #666666;
    font-weight: 300;
  }

  .Draft {
    color: #b1e3ff;
  }

  .Published {
    color: ${(props) => props.theme.palette.info.dark};
  }

  .errorMessage {
    margin: 0;
    margin-top: 10px;
    color: ${(props) => props.theme.palette.error.main};
  }
`;

export interface ProgressNoteProps {
  closeHandler?: Function,
  completeHandler?: Function,
  multipleAlerts: boolean,
}

export default function ProgressNote({
  completeHandler = () => { },
  closeHandler = () => { },
  multipleAlerts,
}: ProgressNoteProps) {
  const { t } = useTranslation();
  const {
    progressNoteContent,
    progressNoteError,
    progressNoteStatus,
    setProgressNoteContent,
    setProgressNoteError,
    setProgressNoteStatus,
  } = useContext(SaveAlertContext);

  return (
    <StyledDiv>

      <Column className="EnterProgressNote">

        <Stack direction="row" spacing={3} sx={{ marginTop: 3 }}>
          <Stack direction="column" spacing={1.5}>
            <FontAwesomeIcon icon={faFileAlt as IconProp} className={`${progressNoteStatus === ProgressNoteStatus.DRAFT ? 'Draft' : 'Published'}`} fontSize="2.8em" />
            <span className="draft-text">
              {progressNoteStatus === ProgressNoteStatus.DRAFT ? t('Procedure.Progress Note.DRAFT') : t('Procedure.Progress Note.PUBLISH')}

            </span>
          </Stack>
          <Stack direction="column" spacing={3} sx={{ flexGrow: 1 }}>
            <CrioTextField
              placeholder={t('Visit.Complete Visit.Enter Progress Note')}
              multiline
              rows={5}
              value={progressNoteContent}
              onFocus={() => setProgressNoteError('')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setProgressNoteContent(e.target.value);
              }}
            />
            {progressNoteError && (
              <span className="errorMessage">{progressNoteError}</span>
            )}

            <RadioGroup
              className="publishOptions"
              onRadioOptionsChange={(_event, options: Array<RadioOption>) => {
                const { value } = options.find((o) => o.checked) || {};
                if (value === ProgressNoteStatus.DRAFT || value === ProgressNoteStatus.PUBLISHED) {
                  setProgressNoteStatus(value);
                }
              }}
              name="progress note type"
              radioOptions={[
                {
                  name: `${t('Procedure.Progress Note.Draft')}`,
                  value: ProgressNoteStatus.DRAFT,
                  checked: progressNoteStatus === ProgressNoteStatus.DRAFT,
                },
                {
                  name: `${t('Procedure.Progress Note.Published')}`,
                  value: ProgressNoteStatus.PUBLISHED,
                  checked: progressNoteStatus === ProgressNoteStatus.PUBLISHED,
                }]}
              row
            />

            <div id="buttonsContainer">
              <div data-qa-id="continue" className="continueButton">
                <CrioButton
                  colorTheme="Primary"
                  onClick={() => {
                    completeHandler({ skip: false });
                  }}
                >
                  {multipleAlerts ? <CheckIcon id="checkIcon" /> : 'Save'}
                </CrioButton>
              </div>
              <div data-qa-id="cancel">
                <CrioButton colorTheme="Secondary" onClick={() => closeHandler()}>{t('Common.Cancel')}</CrioButton>
              </div>
            </div>
          </Stack>
        </Stack>
      </Column>
    </StyledDiv>
  );
}

ProgressNote.defaultProps = {
  closeHandler: () => { },
  completeHandler: () => { },
};
