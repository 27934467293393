import styled from 'styled-components';
import {
  Table, TableCell, TableContainer, TableFooter, TableHead, TableRow, ThemeProvider,
} from '@mui/material';
import { useContext } from 'react';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import crioTheme from '@crio/crio-react-component/dist/cjs/config/crioTheme';
import CrioAlertDialog from '@crio/crio-react-component/dist/cjs/components/Feedback/CrioAlertDialog';
import { TFunction, useTranslation } from 'react-i18next';
import { Column, Row } from '..';
import { QuestionInterface } from '../../types';
import ProcedureContext from '../../context/ProcedureContext';
import { MultiSelectGrid, SingleSelectGrid } from './ProcedureQuestion';

interface GridProps {
  questions: Array<QuestionInterface>,
  options: Array<string>
}

const GridComponent = styled(TableContainer)`
  table {
    border-collapse: separate;
    .GridLabel {
      max-width: 300px;
      font-weight: 400;
      color: ${(props) => props.theme.palette.grey[900]};
      font-size: 0.9rem;
    }
    thead th {
      vertical-align: top;
      position: sticky;
      top: 0;
      z-index: 2;
      .GridHeader {
        align-items: center;
      }
      &:first-of-type {
        border-right: 1px solid ${(props) => props.theme.palette.grey[300]};
        border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
        background-color: ${(props) => props.theme.palette.grey[50]};
        left: 0;
        z-index: 3;
      }
      &:not(:first-of-type) {
        border-top: 1px solid ${(props) => props.theme.palette.grey[300]};
        border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
        background-color: ${(props) => props.theme.palette.grey[200]};
      }
      &:last-of-type {
        border-right: 1px solid ${(props) => props.theme.palette.grey[300]};
      }
    }

    tbody, tfoot  {
      border-right: 1px solid ${(props) => props.theme.palette.grey[300]};
      border-left: 1px solid ${(props) => props.theme.palette.grey[300]};
      border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
      td, th {
        border: none;
        &:not(.GridLabel) {
          vertical-align: top;
        }
        &.LeftCell {
          position: sticky;
          left: 0;
          z-index: 1;
        }
      }
      &:nth-child(odd) {
         background-color: ${(props) => props.theme.palette.grey[100]};
         td.LeftCell {
          background-color: ${(props) => props.theme.palette.grey[100]};
         }
      }
      &:nth-child(even) td.LeftCell {
        background-color: ${(props) => props.theme.palette.grey[50]};
      }
      tr.HintAndCommentRow {
        vertical-align: top;
        td {
          padding-top: 0;
        }
      }
    }
  }
`;

const AlertComponent = styled(CrioAlertDialog)`
.ButtonGroup {
  padding-top: 20px;
  button {
    margin-right: 10px;
    font-weight: 500;
  }
}

`;

const renderGrid = (
  tableHeaderContent: JSX.Element,
  tableBody: JSX.Element[],
  tableFooterContent: JSX.Element[],
) => (
  <GridComponent>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          {tableHeaderContent}
        </TableRow>
      </TableHead>
      {tableBody}
      <TableFooter>
        <TableRow>
          <TableCell className="GridLabel LeftCell"><span>TOTAL</span></TableCell>
          {tableFooterContent}
        </TableRow>
      </TableFooter>
    </Table>
  </GridComponent>
);

function renderToggleAlert(
  showAlert: boolean,
  setShowAlert: (alert: boolean) => void,
  option: string | undefined,
  availableOptions: string[],
  toggleAllOptions: (option: string, availableOptions: string[]) => void,
  toggleTypeTitle: string,
  toggleMessage: string,
  t: TFunction<'translation', undefined>,
) {
  return (
    <AlertComponent
      type="Info"
      alertTitle={`${toggleTypeTitle} "${option}"?`}
      open={showAlert}
      onClose={() => setShowAlert(false)}
    >
      <span className="AlertText" data-testid="toggleConfirmationText">
        {`${toggleMessage} "${option}"?`}
      </span>
      <Row className="ButtonGroup">
        <CrioButton data-testid="toggleConfirmationButton" onClick={() => { toggleAllOptions(option || '', availableOptions); }}>
          {toggleTypeTitle}
        </CrioButton>
        <CrioButton data-testid="toggleCancelButton" colorTheme="Secondary" onClick={() => { setShowAlert(false); }}>{t('Common.Cancel')}</CrioButton>
      </Row>
    </AlertComponent>
  );
}

const StyledColumn = styled(Column)`
  .clearButton {
    color: ${(props) => props.theme.palette.linkText.main};
    cursor: pointer;
    padding-left: 14px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default function Grid({
  options, questions,
}: GridProps) {
  const { t } = useTranslation();
  const {
    saveProcedure, clearProcedure, proceedToNextProcedure, isLoading, readOnly,
  } = useContext(ProcedureContext);

  if (isLoading) return null;
  return (
    <ThemeProvider theme={crioTheme}>
      <StyledColumn className="Grid">
        {questions[0].type === 'MULTI_SELECT' && (
        <MultiSelectGrid
          options={options}
          questions={questions}
          renderGrid={renderGrid}
          renderToggleAlert={renderToggleAlert}
        />
        )}
        {questions[0].type === 'SINGLE_SELECT' && (
        <SingleSelectGrid
          options={options}
          questions={questions}
          renderGrid={renderGrid}
          renderToggleAlert={renderToggleAlert}
        />
        )}
        <Row style={{ marginTop: '30px', alignItems: 'center' }}>
          {!readOnly
            ? (
              <>
                <CrioButton onClick={() => {
                  saveProcedure({ successCallback: proceedToNextProcedure });
                }}
                >
                  {t('Common.Save and Continue')}
                </CrioButton>
                <div
                  role="button"
                  tabIndex={0}
                  className="clearButton"
                  onClick={clearProcedure}
                  onKeyDown={clearProcedure}
                >
                  {t('Common.Clear')}
                </div>
              </>
            )
            : <CrioButton onClick={() => proceedToNextProcedure()}>{t('Common.Continue')}</CrioButton>}
        </Row>
      </StyledColumn>
    </ThemeProvider>
  );
}
