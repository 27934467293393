import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Fragment, useContext, useMemo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { FormControl, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CrioTextField from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioTextField';
import { ChangeReason, SyntheticQuestionType } from '../../../../enums';
import SaveAlertContext from '../../../../context/SaveAlertContext';
import { QuestionInterface } from '../../../../types';

export interface ChangedAnswersProps {
  changedAnswers: Array<ChangedAnswersInterface>,
  closeHandler?: Function,
  completeHandler?: Function,
  deletingRecord: boolean,
  multipleAlerts: boolean,
}

export interface ChangedAnswersInterface {
  recordIndex?: number,
  recordId: string,
  questions: Array<QuestionInterface>
}

const StyledChangeReason = styled.div`
font-family: 'Poppins Light', 'Poppins', 'Poppins', sans-serif;
width: 600px;

.selectReasonDropdown {
  margin: 10px;
  margin-left: 0;
  border-radius: 0;
  border-color: #e6eaf2;
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  width: 200px;
  padding: 0 !important;
  & div {
    color: #999;
    padding: 8px;
  }
}

#checkIcon {
  color: #fff;
}

.commentInput {
  margin: 10px;
  margin-left: 0;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: row;
}

.darken {
  background-color: #fafbfc;
}

#buttonsContainer {
  display: flex;
  align-items: center;
  margin-top: 15px;
  .continueButton {
    padding-left: 0 !important;
    cursor: pointer;
    padding-left: 10px;
    margin-right: 10px;
    &:hover {
      color: #C04040;
    }
  }
}

.changedAnswerQuestionsList {
  margin-top: 0;
  list-style-position: inside;
  .changedAnswerQuestionText {
    padding: 0;
    line-height: 30px;
  }
}

.recordHeading {
  margin-top: 15px;
  font-weight: 400;
}

.errorMessage {
  color: ${(props) => props.theme.palette.error.main};
}
`;

function ChangedAnswers({
  changedAnswers = [],
  closeHandler = () => { },
  completeHandler = () => { },
  deletingRecord,
  multipleAlerts,
}: ChangedAnswersProps) {
  const { t } = useTranslation();
  const {
    changeReason,
    changeReasonDetails,
    changeReasonError,
    setChangeReason,
    setChangeReasonDetails,
    setChangeReasonError,
  } = useContext(SaveAlertContext);

  const ChangeReasonEnumText = useMemo(() => ({
    [ChangeReason.CLARIFICATION_NEW_INFO]: t('Procedure.Dialog.Change Answers.Change Reasons.Clarification'),
    [ChangeReason.DELETION]: t('Procedure.Dialog.Change Answers.Change Reasons.Deletion'),
    [ChangeReason.ERROR]: t('Procedure.Dialog.Change Answers.Change Reasons.Error'),
    [ChangeReason.OTHER]: t('Procedure.Dialog.Change Answers.Change Reasons.Other'),
  }), []);

  const handleSelectChangeReason = (event: SelectChangeEvent<ChangeReason>) => {
    const {
      target: { value },
    } = event;
    setChangeReason(value as ChangeReason);
  };

  return (
    <StyledChangeReason>
      {changedAnswers.map(({ recordId, recordIndex, questions = [] }) => (
        <Fragment key={`${recordId}:${recordIndex}`}>
          {!!recordIndex && !deletingRecord && (<div className="recordHeading">{`${t('Procedure.MultiRecord.Record')} ${recordIndex}`}</div>)}
          <ul className="changedAnswerQuestionsList">
            {deletingRecord
              ? (
                <li className="copy changedAnswerQuestionText" key={`${recordId}`}>
                  {`${t('Procedure.MultiRecord.Record')} ${recordIndex} ${t('Procedure.Dialog.Change Answers.was deleted')}`}
                </li>
              )
              : questions.map(({ type, questionText, questionId }) => {
                const text = type === SyntheticQuestionType.NO_ENTRY ? t('Procedure.MultiRecord.Are there records') : questionText;
                return (<li className="copy changedAnswerQuestionText" key={`${recordId}-${questionId}`}>{text}</li>);
              })}
          </ul>
        </Fragment>
      ))}

      <span className="instructions">
        {t('Procedure.Dialog.Change Answers.Enter change reason')}
      </span>
      <FormControl className="form">
        <Select
          value={changeReason || ''}
          onChange={handleSelectChangeReason}
          className={`copy selectReasonDropdown ${!multipleAlerts ? 'darken' : ''}`}
          displayEmpty
        >
          {[ChangeReason.CLARIFICATION_NEW_INFO, ChangeReason.ERROR, ChangeReason.OTHER].map((type) => (
            <MenuItem
              key={type}
              value={type}
              style={{ fontSize: '14px' }}
            >
              {ChangeReasonEnumText[type]}
            </MenuItem>
          ))}
        </Select>

        <CrioTextField
          className="commentInput"
          multiline
          placeholder="Enter Reason Details"
          rows={1}
          onChange={(e) => setChangeReasonDetails(e.target.value)}
          value={changeReasonDetails}
          onFocus={() => setChangeReasonError('')}
        />
      </FormControl>

      {changeReasonError && (
        <span className="errorMessage">{changeReasonError}</span>
      )}
      <div id="buttonsContainer">
        <div data-qa-id="continue" className="continueButton">
          <CrioButton
            colorTheme="Primary"
            onClick={() => {
              completeHandler({});
            }}
          >
            {multipleAlerts ? <CheckIcon id="checkIcon" /> : 'Save'}
          </CrioButton>
        </div>
        <div data-qa-id="cancel">
          <CrioButton
            colorTheme="Secondary"
            onClick={() => {
              closeHandler();
            }}
          >
            {t('Common.Cancel')}
          </CrioButton>
        </div>
      </div>
    </StyledChangeReason>
  );
}

ChangedAnswers.defaultProps = {
  closeHandler: () => { },
  completeHandler: () => { },
};

export default ChangedAnswers;
