/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import {
  AnswerType,
  CarryForwardType,
  isSyntheticAnswerType,
  ProcedureDisplayType,
  ProcedureOverrideStatus,
  ProcedureStatus,
  QuestionFixedAnswerType,
  SyntheticQuestionType,
  VisitModeType,
  VisitStatus,
} from '../../enums';
import {
  DataPoint, ProcedureInterface, QuestionAnswer, QuestionInterface, QuestionWithAnswerInterface,
} from '../../types';
import { ProcedureRecord, ProcedureRecords, SimplifiedRecords } from '../types';
import { SaveDataPointsResponse } from '../../api/types';
import { determineIfCommentNeeded } from '../../util/answerComments';
import { isAnswerUnanswered, isDataPointAnswered, isDataPointSkipped } from '../../util/esourcePredicateUtil';
import VariableNameAnswers from '../types/VariableNameAnswers';

interface GetAllUnansweredQuestionsProps {
  procedureId: string,
  displayType: ProcedureDisplayType,
  records: ProcedureRecords,
  questions: Array<QuestionInterface>,
  carryForwardType: CarryForwardType,
}

interface GetAllChangedAnswersProps {
  procedureId: string,
  displayType: ProcedureDisplayType,
  initialRecords: ProcedureRecords,
  currentRecords: ProcedureRecords,
  questions: Array<QuestionInterface>,
}

interface GetDataPointsForSavingProps {
  currentRecords: ProcedureRecords,
  deletingRecord: boolean,
  procedureId: string,
  initialRecords: ProcedureRecords,
  visitMode?: VisitModeType,
  skipProcedure: boolean,
}

interface GetInitialRecordsProps {
  displayType: ProcedureDisplayType,
  procedureId: string,
  getRulesEngine: Function,
  getDefaultedRecord: Function,
  unfilteredDataPoints: Array<DataPoint>,
}

interface BasicAnswerInformation {
  [recordId: string]: Array<UserEnteredInformation>,
}

interface UserEnteredInformation {
  answer: QuestionAnswer,
  answer_comment?: string,
  answer_user_id?: string,
  is_disabled?: boolean,
  is_active?: boolean,
  procedure_override_status?: ProcedureOverrideStatus,
}

const CONFIGURATION_RECORD_ID = 'configuration';

const findSyntheticDataPointFromRecords = (records: ProcedureRecords, type: SyntheticQuestionType, procedureId: string): DataPoint | undefined => records[procedureId]?.record[type];

/**
 * Helper function to get the Data Points from a Procedure Record
 * @param procedureRecord the procedure record containing the datapoints
 * @return                Array<DataPoint> containing the Data Points in the Procedure Records
 */
const getDataPointsFromProcedureRecord = (procedureRecord: ProcedureRecord): Array<DataPoint> => Object.values(procedureRecord);

/**
 * Helper function to get the Data Points from a given set of Procedure Records
 * @param procedureRecords   ProcedureRecords to pull the Data Points from
 * @return                   Array<DataPoint> contianing all Data Points in the Procedure Records
 */
const getDataPointsFromProcedureRecords = (procedureRecords: ProcedureRecords): Array<DataPoint> => Object.values(procedureRecords)
  ?.map((procedureRecord) => procedureRecord.record)
  .flatMap((r) => getDataPointsFromProcedureRecord(r));

/**
 * Helper function to format a ProcedureRecord into the justAnswers format which is used by the rules engine:
 * { variableName: answer }
 * @param procedureRecord the ProcedureRecord that has the data
 * @return                a justAnswers json object for passing into the rules engine
 */
const justAnswersFromProcedureRecord = (procedureRecord: ProcedureRecord): VariableNameAnswers => {
  const justAnswers: any = {};
  Object.keys(procedureRecord).forEach((questionId: string) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { answer, answer_type, question_variable_name } = procedureRecord[questionId];
    if (!question_variable_name) return;
    switch (answer_type) {
      // Do not run rules against has_changes or no_entry datapoints
      case AnswerType.HAS_CHANGES:
      case AnswerType.NO_ENTRY:
        break;
      // Convert strings to datetimes, if needed
      case AnswerType.DATE_TIME:
        // eslint-disable-next-line no-case-declarations
        const dateTimeAnswerNeedsConversion = typeof answer === 'string';
        justAnswers[question_variable_name] = dateTimeAnswerNeedsConversion ? DateTime.fromFormat(answer, 'dd-MMM-yyyy HH:mm') : answer;
        break;
      // Convert strings to datetimes, if needed (ignore ONGOING)
      case AnswerType.DATE:
        // eslint-disable-next-line no-case-declarations
        const dateAnswerNeedsConversion = typeof answer === 'string' && answer !== 'ONGOING';
        justAnswers[question_variable_name] = dateAnswerNeedsConversion ? DateTime.fromFormat(answer, 'dd-MMM-yyyy') : answer;
        break;
      // Otherwise just copy the answer unchanged
      default:
        justAnswers[question_variable_name] = answer;
    }
  });
  return justAnswers;
};

// From an array of DataPoints corresponding to procedure questions, return an array
// with a single datapoint corresponding to just the procedure (a datapoint with no question or answer info)
const _getProcedureDataPointFromQuestionDataPoints = (dataPoints: Array<DataPoint>): Array<DataPoint> => {
  if (!dataPoints || dataPoints.length === 0) return dataPoints;
  const {
    // strip out all answer fields except for answer_user, answer_user_id, answer_completed_date
    answer, answer_code, answer_comment, answer_id, answer_type,
    // strip out all question fields
    question_cdash_name, question_template_level, question_id, question_name, question_order, question_variable_name,
    ...restOfDataPoint
  } = dataPoints[0];
  return [restOfDataPoint as DataPoint];
};

const deepCopyRecords = (records: ProcedureRecords): ProcedureRecords => {
  const newRecords = JSON.parse(JSON.stringify(records));
  Object.keys(newRecords).forEach((recordId) => {
    // Not duping the rules engine, just the datapoints
    newRecords[recordId].rulesEngine = records[recordId].rulesEngine;
  });
  return newRecords;
};

/**
 * Given a server response after saving data points, reconcile the existing records in our context. This
 * routine is run twice. Once to bring the existing records up to date and again to bring the existing
 * "initial" records up to date. It's run twice because we use these two data structures to determine what
 * changes have happened between individual saves
 * @param existingRecords The full set of existing records
 * @param saveDataPointsResponse The response from the server indicating what it has just saved
 * @returns Reconciled records
 */
const getUpdatedRecords = (existingRecords: ProcedureRecords, saveDataPointsResponse: SaveDataPointsResponse, displayType: ProcedureDisplayType): ProcedureRecords => {
  const { success: savedDataPoints } = saveDataPointsResponse;
  const newRecords = deepCopyRecords(existingRecords);

  savedDataPoints.forEach((savedDataPoint) => {
    const {
      answer_type, procedure_id, question_id, record_id, is_active,
    } = savedDataPoint;
    // If datapoint has status of skipped, it is a synthetic one, so there won't be
    // a datapoint in our records to match to, so just continue
    if (isDataPointSkipped(savedDataPoint)) return;

    // Get the recordId. The recordId could be either the procedureId, a multirecord recordId, or
    // a generic CONFIGURATION_RECORD_ID indicating that it represents the configuration of a
    // multirecord and is not anything use-entered
    const id = (() => {
      if (displayType === ProcedureDisplayType.MULTI_RECORD) {
        // Synthetic answers are always added to the procedure itself. If it's not a synthetic
        // answer and there's also no multirecord recordId, then it's part of the configuration
        // record.
        return isSyntheticAnswerType(answer_type) ? procedure_id : record_id || CONFIGURATION_RECORD_ID;
      }

      // It's very simple to determine what the record id is if the procedure is not multirecord
      return procedure_id;
    })();

    // In the SPA, synthetic questions have questionIds equal to the answerType
    // (as opposed to no questionId like in the backend/database)
    let resolvedQuestionId = question_id;
    if (answer_type === AnswerType.NO_ENTRY) {
      resolvedQuestionId = SyntheticQuestionType.NO_ENTRY;
    }
    if (answer_type === AnswerType.HAS_CHANGES) {
      resolvedQuestionId = SyntheticQuestionType.HAS_CHANGES;
    }

    try {
      // Find the matched datapoint in our full set of existing records that our context already knows about
      const matchedDataPoint = newRecords[id]?.record[resolvedQuestionId];

      // If we found a datapoint in our full set of existing records and the server indicates that it was deleted,
      // remove it from our context too. The other use case of deleting datapoints from our existing records is if
      // the thing the server returned was a configuration record. Those are system generated and not really records
      // and therefore shouldn't be viewable by users as they're modifying a procedure.
      if (matchedDataPoint && (!is_active || id === CONFIGURATION_RECORD_ID)) {
        delete newRecords[id].record[resolvedQuestionId];
        // If no more datapoints associated with record, delete the record too
        if (Object.keys(newRecords[id].record).length === 0) {
          delete newRecords[id];
        }
      } // eslint-disable-line @typescript-eslint/brace-style
      // If we matched a normal datapoint in our full set of existing records, update it with the response from the
      // server. The server contains a more up to date version of our data including answer_ids among other things.
      else if (matchedDataPoint) {
        // Modified a datapoint
        newRecords[id].record[resolvedQuestionId] = {
          ...savedDataPoint,
          question_id: resolvedQuestionId,
        };
      } // eslint-disable-line @typescript-eslint/brace-style
      // If we didn't match a datapoint in our full set of existing records then we conditionally add it. This
      // mostly happens when we're bringing our existing "initial" records up to date and we've added a new record
      // since the initial records were first snapshotted. There are a couple exceptions to adding datapoints back.
      // Firstly, if it's a configuration record, that's system generated and not something we want to show the user
      // or use in diff calculations. Secondly if this saved datapoint was deleted we don't want to add it back.
      else if (id !== CONFIGURATION_RECORD_ID && is_active) {
        // If the id doesn't exist in our existing record at all, we need to initialize it. This only happens
        // when we're bringing the existing "initial" records up to speed, so rules engine being an empty object
        // is ok, we're not actually using the rules engine for anything in initial records
        if (!newRecords[id]) {
          newRecords[id] = {
            record: {},
            rulesEngine: {},
          };
        }

        newRecords[id].record[resolvedQuestionId] = {
          ...savedDataPoint,
          question_id: resolvedQuestionId,
        };
      } // eslint-disable-line @typescript-eslint/brace-style
    } catch {
      // eslint-disable-next-line no-console
      console.error(`Could not match up datapoint we recieved from the server: recordId: ${record_id || procedure_id}, questionId: ${resolvedQuestionId}`);
    }
  });

  return newRecords;
};

/**
 * Populate the ProcedureRecords with information retrieved from data points
 */
const getInitialRecords = (props: GetInitialRecordsProps): ProcedureRecords => {
  const {
    displayType,
    getDefaultedRecord,
    getRulesEngine,
    procedureId,
    unfilteredDataPoints,
  } = props;
  const newRecords: ProcedureRecords = {};
  const dataPoints = unfilteredDataPoints.filter((dataPoint) => dataPoint.is_active !== false);

  // Adding defaulted procedure config data points
  if (!newRecords[procedureId]) {
    const rulesEngine = getRulesEngine(procedureId);
    const record: ProcedureRecord = getDefaultedRecord(procedureId);
    if (Object.keys(record).length > 0) {
      newRecords[procedureId] = {
        record,
        rulesEngine,
      };
    }

    // Since we are now ALWAYS adding a no_entry question, there could be a case in legacy data
    // where there was no no_entry question previously and they added records. If this happens
    // we need to make sure that we default the no_entry answer to '1' (has records). A null record_id
    // means "configuration" record, so don't count that one, it's not user-entered
    const isMultiRecordWithRecords = dataPoints.findIndex(({ record_id }) => record_id && record_id !== procedureId) > -1;
    if (isMultiRecordWithRecords && newRecords[procedureId]) {
      newRecords[procedureId].record[SyntheticQuestionType.NO_ENTRY].answer = '1';
    }
  }

  // For each data point we received from the backend, update the ProcedureRecord
  dataPoints.forEach((dataPoint: DataPoint) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {
      question_id, record_id, answer_type, procedure_id,
    } = dataPoint;
    // The id we're looking to match is either the procedure_id or the record_id, both
    // are stored in the same object in the context. The record_id could be null in the
    // case that we're recieving a configuration record
    const id = (() => {
      if (displayType === ProcedureDisplayType.MULTI_RECORD) {
        return isSyntheticAnswerType(answer_type) ? procedure_id : record_id;
      }

      return procedure_id;
    })();

    // Synthetic questions don't have a questionId when receievd from backend
    if (isSyntheticAnswerType(answer_type)) {
      if (answer_type === AnswerType.NO_ENTRY) {
        newRecords[id!].record[SyntheticQuestionType.NO_ENTRY] = {
          ...dataPoint,
          question_id: SyntheticQuestionType.NO_ENTRY,
        };
      }

      if (answer_type === AnswerType.HAS_CHANGES) {
        newRecords[id!].record[SyntheticQuestionType.HAS_CHANGES] = {
          ...dataPoint,
          question_id: SyntheticQuestionType.HAS_CHANGES,
        };
      }
      return;
    }

    // Adding multirecords
    if (record_id && !newRecords[id!]) {
      const record = getDefaultedRecord(record_id);
      const rulesEngine = getRulesEngine(id);
      newRecords[id!] = {
        record,
        rulesEngine,
      };
    }

    try {
      newRecords[id!].record[question_id] = { ...dataPoint };
    } catch (_err) {
      // Will only hit here if the id is null meaning a configuration record - we want to filter these out anyways
    }
  });

  return newRecords;
};

interface GetReferencedRecordsProps {
  procedure: ProcedureInterface,
  referencedDataPoints: Array<DataPoint>,
}
/**
 * Populate the ProcedureRecords with information retrieved from referenced data points
 */
const getReferencedRecords = (props: GetReferencedRecordsProps): ProcedureRecords => {
  const {
    procedure,
    referencedDataPoints,
  } = props;

  const lookup: ProcedureRecord = {};
  referencedDataPoints.reduce((acc, dataPoint) => {
    acc[dataPoint.question_id] = dataPoint;
    return acc;
  }, lookup);

  const record: ProcedureRecord = {};
  procedure.questions.filter((q) => q.criteria !== undefined).reduce((acc, q) => {
    const { questionId, criteria } = q;
    const { questionId: referencedQuestionId } = criteria!;

    record[questionId] = lookup[referencedQuestionId];

    return acc;
  }, record);

  const newRecords: ProcedureRecords = {};
  newRecords[procedure.procedureId] = {
    record,
    rulesEngine: null,
  };

  return newRecords;
};

const getUnansweredQuestionsForRecord = (dataPoints: ProcedureRecord, questions: Array<QuestionInterface>, multiRecordHasZeroRecords: boolean, carryForwardType: CarryForwardType): Array<QuestionWithAnswerInterface> => {
  const missingQuestionList: Array<QuestionWithAnswerInterface> = [];

  Object.keys(dataPoints)
    .filter((questionId) => !dataPoints[questionId].is_disabled && dataPoints[questionId].is_active)
    .forEach((questionId: string) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { answer, answer_comment, answer_type } = dataPoints[questionId];
      const question = questions.find(({ questionId: qQuestionId }) => questionId === qQuestionId);
      if (question) {
        const { answerOptions } = question;
        const commentNeeded = determineIfCommentNeeded(answerOptions, answer, answer_comment);

        if (isAnswerUnanswered(answer)
          // Missing required comments counts as a missing answer
          || commentNeeded
          // If this is a no entry question for a normal multirecord (perm records don't contribute to visit status)
          // without any existing records, and the answer is not '0' nor '[NOT DONE]'
          // (meaning the answer is '1' or unanswered), then push the no entry question as this is incomplete
          || (answer_type === AnswerType.NO_ENTRY && answer !== '0'
            && answer !== QuestionFixedAnswerType.NOT_DONE && multiRecordHasZeroRecords && carryForwardType !== CarryForwardType.PERMANENT)
        ) {
          missingQuestionList.push({
            ...question,
            answer,
            answerComment: answer_comment,
          });
        }
      }
    });

  return missingQuestionList;
};

const getAllUnansweredQuestions = ({
  procedureId,
  displayType,
  records,
  questions,
  carryForwardType,
}: GetAllUnansweredQuestionsProps) => {
  const missingRecordQuestionList: SimplifiedRecords = [];
  let recordIndex = 0;
  const multiRecordHasZeroRecords = !getDataPointsFromProcedureRecords(records).find(({ is_active, record_id }) => is_active && record_id);
  Object.keys(records)
    .forEach((recordId) => {
      const { record } = records[recordId];
      const isMultiRecordRecord = displayType === ProcedureDisplayType.MULTI_RECORD && recordId !== procedureId;
      const missingQuestions = getUnansweredQuestionsForRecord(record, questions, multiRecordHasZeroRecords, carryForwardType);

      if (isMultiRecordRecord) recordIndex += 1;
      if (missingQuestions.length > 0) {
        missingRecordQuestionList.push({
          recordIndex: isMultiRecordRecord ? recordIndex : undefined,
          recordId,
          questions: missingQuestions,
        });
      }
    });
  return missingRecordQuestionList;
};

const wasAnythingEverAnswered = (procedureRecord: ProcedureRecord, changedAnswersRecordQuestionList: SimplifiedRecords = [], missingRecordQuestionsList: SimplifiedRecords = []) => {
  // If we detect that an answer has changed, even if that change goes back to blank, then this procedure has been answered before
  if (changedAnswersRecordQuestionList.length > 0) {
    return true;
  }

  // Look at the missing question list for the procedure. Filtering out questions that are part of the list because of a
  // missing comment, determine if the length matches the number of questions in the procedure. If so, then this procedure
  // has not been answered. We don't have to check multirecord records because if there's missing answers for a multirecord,
  // that must mean they've answered one of the procedure-level questions (probably the no_records question)
  let missingProcedureQuestions = missingRecordQuestionsList.find(({ recordIndex }) => !recordIndex)?.questions || [];
  missingProcedureQuestions = missingProcedureQuestions.filter(({ answer }) => isAnswerUnanswered(answer));
  return missingProcedureQuestions.length !== Object.keys(procedureRecord).length;
};

/**
 * Helper function to find the first defined instance of an attribute in the given list of Data Points
 * This is useful for pulling attributes that supersede the Question-level (e.g.: Study-related or Visit-related attributes)
 * and will likely be the same on all Data Points in the list
 * @param dataPoints     Array<DataPoint> of data points to search
 * @param attributeName  keyof DataPoint to search for in the list
 * @return               any of the first, defined attribute under the given attribute name
 */
const getDataPointsAttribute = (dataPoints: Array<DataPoint>, attributeName: keyof DataPoint): any => dataPoints
  ?.map((dataPoint: DataPoint) => dataPoint[attributeName])
  .find((attribute: any) => attribute !== undefined);

/**
 * Given a list of datapoints, check if this is traits or carry forward yes and therefore need to rerun procedure rules.
 * @param dataPoints the datapoints to be examined
 */
const shouldRunRulesInitiallyForDataPoints = (dataPoints: Array<DataPoint>): boolean => dataPoints
  .filter((dataPoint: DataPoint) => dataPoint.answer_id === undefined)
  .length > 0;

/**
 * Get the Visit Status (or the default one if none exists yet)
 */
const getDataPointsVisitStatus = (dataPoints: Array<DataPoint>): VisitStatus => getDataPointsAttribute(dataPoints, 'visit_status') || VisitStatus.PAUSED;

const calculateStatusFromMissingQuestions = (missingRecordQuestionsList: SimplifiedRecords, skipProcedure: boolean): ProcedureStatus => {
  const hasMissingQuestions = missingRecordQuestionsList.length > 0
    && missingRecordQuestionsList.some(({ questions }) => questions && questions.length > 0);
  let newProcedureStatus: ProcedureStatus = hasMissingQuestions ? ProcedureStatus.PARTIALLY_COMPLETED : ProcedureStatus.COMPLETED;
  newProcedureStatus = skipProcedure ? ProcedureStatus.SKIPPED : newProcedureStatus;
  return newProcedureStatus;
};

const simplifyDataPointForChangeCheck = (dataPoint: DataPoint): UserEnteredInformation => {
  const {
    answer, answer_comment, is_disabled, procedure_override_status,
  } = dataPoint || {};

  let simplifiedAnswer = `${answer}` || undefined;
  if (!isDataPointAnswered(dataPoint)) {
    simplifiedAnswer = undefined;
  }
  return {
    // answer of NaN comes from the rules engine when it really should still be considered unansered
    answer: simplifiedAnswer,
    answer_comment,
    is_disabled,
    procedure_override_status,
  };
};

const simplifyRecordForChangeCheck = (records: ProcedureRecords): BasicAnswerInformation => {
  const basicAnswers: BasicAnswerInformation = {};
  Object.keys(records).forEach((recordId: string) => {
    const questionIds = Object.keys(records[recordId].record);
    basicAnswers[recordId] = questionIds.map((questionId) => simplifyDataPointForChangeCheck(records[recordId].record[questionId]));
  });
  return basicAnswers;
};

const getHasUnsavedChange = (newDataPoint: DataPoint, initialDataPoint: DataPoint): boolean => {
  const initialRecordAnswer = simplifyDataPointForChangeCheck(initialDataPoint);
  const currentRecordAnswer = simplifyDataPointForChangeCheck(newDataPoint);

  return JSON.stringify(currentRecordAnswer) !== JSON.stringify(initialRecordAnswer);
};

const getHasUnsavedChanges = (currentRecords: ProcedureRecords, initialRecords: ProcedureRecords): boolean => {
  const initialRecordAnswers = simplifyRecordForChangeCheck(initialRecords);
  const currentRecordAnswers = simplifyRecordForChangeCheck(currentRecords);

  return JSON.stringify(currentRecordAnswers) !== JSON.stringify(initialRecordAnswers);
};

const getChangedAnswerQuestionsForRecord = (initialDataPoints: ProcedureRecord, currentDataPoints: ProcedureRecord, questions: Array<QuestionInterface>): Array<QuestionInterface> => {
  const changedAnswersList: Array<QuestionInterface> = [];
  Object.keys(initialDataPoints)
    // Ensure there is an answer_user_id. We don't use answer_id because blank SVPQs that were never
    // answered still get a answer_id and we don't want to trigger a change reason in that scenario
    .filter((questionId) => !!initialDataPoints[questionId].answer_user_id)
    .forEach((questionId: string) => {
      const initialDataPoint = initialDataPoints[questionId];
      const currentDataPoint = currentDataPoints[questionId];
      const question = questions.find(({ questionId: qQuestionId }) => questionId === qQuestionId);

      if (question) {
        const simplifiedInitialDataPoint = simplifyDataPointForChangeCheck(initialDataPoint);
        if (!currentDataPoint) { // Deleted
          changedAnswersList.push(question);
        } else {
          const simplifiedCurrentDataPoint = simplifyDataPointForChangeCheck(currentDataPoint);
          if (JSON.stringify(simplifiedCurrentDataPoint) !== JSON.stringify(simplifiedInitialDataPoint)) {
            changedAnswersList.push(question);
          }
        }
      }
    });
  return changedAnswersList;
};

const getAllChangedAnswers = ({
  procedureId,
  displayType,
  initialRecords,
  currentRecords,
  questions,
}: GetAllChangedAnswersProps) => {
  const changedRecordQuestionList: SimplifiedRecords = [];
  let recordIndex = 0;
  Object.keys(initialRecords)
    .forEach((recordId) => {
      const { record: initialRecord } = initialRecords[recordId];
      const { record: currentRecord } = currentRecords[recordId] || {};
      if (displayType === ProcedureDisplayType.MULTI_RECORD && recordId !== procedureId) recordIndex += 1;
      const changedAnswerQuestions = getChangedAnswerQuestionsForRecord(initialRecord, currentRecord, questions);
      if (changedAnswerQuestions.length > 0) {
        changedRecordQuestionList.push({
          recordIndex: displayType === ProcedureDisplayType.MULTI_RECORD ? recordIndex : undefined,
          recordId,
          questions: changedAnswerQuestions,
        });
      }
    });

  return changedRecordQuestionList;
};

const getDataPointsForSaving = ({
  currentRecords,
  deletingRecord,
  initialRecords,
  procedureId,
  visitMode,
  skipProcedure,
}: GetDataPointsForSavingProps): Array<DataPoint> => {
  const { answer: areThereRecordsAnswer } = findSyntheticDataPointFromRecords(currentRecords, SyntheticQuestionType.NO_ENTRY, procedureId) || {};
  let dataPointsToSave: Array<DataPoint> = [];
  dataPointsToSave.push(...getDataPointsFromProcedureRecords(currentRecords));

  // If we are skipping the procedure, don't bother post-processing the question datapoints, just
  // use one of them to construct the synthetic datapoint for the procedure
  if (skipProcedure) return _getProcedureDataPointFromQuestionDataPoints(dataPointsToSave);

  dataPointsToSave = dataPointsToSave
    // If noEntry was selected, then filter out all the records they were working on - we're not saving that
    .filter((dataPoint) => {
      if (areThereRecordsAnswer === '0') {
        return !dataPoint.record_id;
      }

      return true;
    })
    // Filter out records that have not changed
    .filter((dataPoint) => {
      const {
        is_active,
        answer_id: answerId,
        question_id: questionId,
        record_id: recordId,
      } = dataPoint;

      // if we are deleting a record, we only want to save the datapoints associated with that deleted record
      if (deletingRecord) { return !is_active; }

      if (!answerId) { return true; }

      return getHasUnsavedChange(dataPoint, initialRecords[recordId || procedureId]?.record[questionId]);
    })
    .map((dataPoint) => {
      const { question_id, answer_id, answer_type } = dataPoint;

      // Last minute modifications that need to happen LAST
      return {
        ...dataPoint,
        // Null out the answer_id to trigger a regeneration on backend
        answer_id: visitMode === VisitModeType.SANDBOX ? answer_id : undefined,
        // Null out the question_id if it's a synthetic to mesh with how the backend expects it
        question_id: isSyntheticAnswerType(answer_type) ? '' : question_id,
      };
    });

  return dataPointsToSave;
};

export {
  CONFIGURATION_RECORD_ID,
  calculateStatusFromMissingQuestions,
  deepCopyRecords,
  findSyntheticDataPointFromRecords,
  getAllChangedAnswers,
  getAllUnansweredQuestions,
  getDataPointsAttribute,
  getDataPointsForSaving,
  getHasUnsavedChange,
  getHasUnsavedChanges,
  getInitialRecords,
  getReferencedRecords,
  getDataPointsFromProcedureRecord,
  getDataPointsFromProcedureRecords,
  getDataPointsVisitStatus,
  getUpdatedRecords,
  justAnswersFromProcedureRecord,
  shouldRunRulesInitiallyForDataPoints,
  wasAnythingEverAnswered,
};
